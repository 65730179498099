import api from './index';

const getReconsideracoes = (page, itemsPerPage, filtrosAplicados = []) => api.get('pesquisaReconsideracoes', {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados}});

const getReconsideracao = (id_reconsideracao) => api.get('reconsideracoes/' + id_reconsideracao);

const createReconsideracao = (reconsideracao) => api.post('reconsideracoes', reconsideracao);

const updateReconsideracao = (id_reconsideracao, reconsideracao) => api.put('reconsideracoes/'+id_reconsideracao, reconsideracao);

const updateReconsideracaoFromVisualizacaoServidor = (id_reconsideracao, reconsideracao) => api.put('reconsideracoes-servidor/'+id_reconsideracao, reconsideracao);

const extracaoReconsideracao = (filtrosAplicados = []) => api.get('extracaoReconsideracao', { params: {filtros: filtrosAplicados}, responseType: 'blob' } );

const excluirReconsideracao = (id, data) => api.post(
    'excluirReconsideracao/'+id,
    data
);

export {
    getReconsideracoes,
    getReconsideracao,
    createReconsideracao,
    updateReconsideracao,
    updateReconsideracaoFromVisualizacaoServidor,
    extracaoReconsideracao,
    excluirReconsideracao
}