import api from './index';

const getUnidades = () => api.get('unidades');
const getUnidade = (id_unidade) => api.get('unidades/' + id_unidade);
const getUnidadesTree = () => api.get('unidades/tree/all');
const getUnidadeDetalhes = (id_unidade) => api.get('unidade/detalhes/' + id_unidade);

export {
    getUnidades
    ,getUnidade
    ,getUnidadesTree
    ,getUnidadeDetalhes
}