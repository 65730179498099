<template>
    <div class="pt-8">
        <p>
        	O AvaliaMGI é o sistema responsável por implementar o processo de avaliação de desempenho individual e institucional, para fins, entre outros,  de pagamento de gratificações de desempenho devida aos servidores ocupantes de cargos efetivos do quadro de pessoal do Ministério da Gestão e Inovação em Serviços Públicos.
		</p>
        <p>
            Para acessá-lo, é necessário ter uma conta <a href="https://contas.acesso.gov.br/" target="_blank">gov.br</a> e ser servidor do Ministério da Gestão e Inovação em Serviços Públicos.  
        </p>
    </div>
</template>


<script>
    export default {
        name: 'logout',
        created() {
		},
    }
</script>