<template>
    <div>
        <afastamentoServidorDialog ref="afastamentoServidorDialog" />
        <liberarBloquearAvaliacaoDialog ref="liberarBloquearAvaliacaoDialog" />
        <h1>Gerenciar servidores afastados</h1>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="cpf" 
                                v-model="filtros.nr_cpf" 
                                ref="cpf" 
                                dense 
                                hide-details="auto" 
                                label="CPF" 
                                v-mask="'###.###.###-##'" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="nome" 
                                v-model="filtros.nm_servidor" 
                                ref="nome" 
                                dense 
                                hide-details="auto" 
                                label="Nome" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="siape" 
                                ref="siape" 
                                v-model="filtros.nr_siape" 
                                dense 
                                hide-details="auto" 
                                label="SIAPE" 
                                v-mask="'#######'" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="gratificacao"
                                :items="gratificacoes"
                                v-model="filtros.id_gratificacao"
                                item-value="id_gratificacao"
                                item-text="sg_gratificacao"
                                label="Gratificação"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="codigosAfastamentos"
                                :items="codigosAfastamentos"
                                v-model="filtros.id_situacao_afastamento"
                                item-value="id_situacao_afastamento"
                                item-text="co_afastamento_siape"
                                label="Código do Afastamento"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="situacoes"
                                :items="situacoes"
                                v-model="filtros.situacoes"
                                label="Situação"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="10" class="text-right"></v-col>
                        <v-col 
                            cols="2" 
                            class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                            <v-btn 
                                id="btnFiltrar" 
                                color="primary" 
                                @click="gerarRelatorioAfastamentoServidor()"
                            >
                                <v-icon>mdi-magnify</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <br />

        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="relatorioAfastamentoServidor"
                    :items-per-page="pagination.itemsPerPage"
                    :footer-props="{'items-per-page-options':[10]}"
                    :options.sync="pagination" 
                    :server-items-length="pagination.total"
                    class="elevation-1"
                    :hide-default-footer = true
                >
                    <template v-slot:footer v-if="pagination.total">
                        <v-divider></v-divider><br>
                        <v-pagination
                            v-model="pagination.current"
                            :length="last_page"
                            :total-visible="5"
                            circle
                            small
                            @input="gerarRelatorioAfastamentoServidor"
                        ></v-pagination>
                        <h6 class="text-center grey--text">Total: {{pagination.total}}</h6>
                    </template>

                    <template v-slot:[`item.ds_email_intitucional`]="{ item }">
                        <v-row>
                            {{item.ds_email_intitucional ? item.ds_email_intitucional : item.ds_email_pessoal}}
                        </v-row>
                    </template>

                    <template v-slot:[`item.situacao`]="{ item }">
                        <v-row  v-if="item.situacao && !item.st_repetir_nota">
                            <v-btn class="white--text" color="error" rounded x-small>
                                {{ item.situacao }}
                            </v-btn>
                        </v-row>
                        <v-row  v-if="item.situacao && item.st_repetir_nota">
                            <v-btn class="white--text" color="success" rounded x-small>
                                {{ item.situacao }}
                            </v-btn>
                        </v-row>
                    </template>
                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row justify="center" class="pa-4">
                            <v-menu bottom left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn 
                                        fab 
                                        dark 
                                        x-small 
                                        color="blue-grey" 
                                        v-bind="attrs" 
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-tooltip top>
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn 
                                                    class="mr-2 mt-1 mb-1" 
                                                    id="btnVisualizar" 
                                                    icon 
                                                    small 
                                                    :color="'primary'" 
                                                    v-bind="attrs" 
                                                    v-on="on" 
                                                    @click="() => modalDetalheAfastamento(item.id_servidor)"
                                                >
                                                    <v-icon> mdi-eye </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Visualizar Afastamentos</span>
                                        </v-tooltip>
                                        <v-tooltip 
                                            top 
                                            v-if=" item.avaliacaoBloqueada
                                                || item.situacao == 'BLOQUEADO'
                                                || item.situacao == 'NÃO AVALIÁVEL'
                                                || item.situacao == 'REPETIR NOTA'
                                                || item.situacao == 'VERIFICAR AVALIAÇÃO'
                                                || item.situacao == 'VERIFICAR REPETIÇÃO DE NOTA'">
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn 
                                                    class="mr-2 mb-1 mt-1" 
                                                    id="btnRepetirNota" 
                                                    icon 
                                                    small 
                                                    :color="'primary'" 
                                                    v-bind="attrs" 
                                                    v-on="on"
                                                    @click="showDialogRepetirNota(item.id_servidor)"
                                                >
                                                    <v-icon>mdi-content-duplicate</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Desbloquear avaliação / Repetir nota</span>
                                        </v-tooltip>
                                        <v-tooltip top v-else>
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn 
                                                    class="mr-2 mb-1 mt-1" 
                                                    id="btnliberar" 
                                                    icon 
                                                    small 
                                                    :color="'primary'" 
                                                    v-bind="attrs" 
                                                    v-on="on" 
                                                    @click="()=>liberarOuBloquearAvaliacao(
                                                        item.avaliacaoBloqueada, 
                                                        item.id_servidor
                                                    )"
                                                >
                                                    <v-icon>mdi mdi-lock</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Bloquear Avaliação</span>
                                        </v-tooltip>
                                        
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="pagination.total">
            <v-col cols="10" class="text-right"></v-col>
            <v-col cols="2" class="ma-0 mb-6 pa-2 px-4 d-flex flex-row justify-end align-end text-right elevation-0">
                <v-btn color="success" @click="exportarAfastamentoServidor()">
                    <v-icon>mdi-download</v-icon> Exportar para excel
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogRepetirNota" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-card-title>
                        <v-icon large left> mdi-content-duplicate </v-icon>
                        <span class="text-h6 font-weight-light">
                            Desbloquear avaliação / Repetir nota
                        </span>
                    </v-card-title>
                </v-card>
                <br>
                <v-container>
                    <h3 class="text-center font-weight-light primary--text">
                        Desbloquear avaliação / Repetir nota
                    </h3>
                    <v-radio-group v-model="radioGroup" class="mt-3">
                        <v-row>
                            <v-col cols="3"></v-col>
                            <v-col cols="3" class="pr-0">
                                <v-radio
                                    label="Desbloquear"
                                    color="primary"
                                    value="1"
                                    required
                                    :rules="[() => !!radioGroup|| 'Campo obrigatório.']"
                                ></v-radio>
                            </v-col>
                            <v-col cols="3">
                                <v-radio
                                    label="Repetir nota"
                                    color="primary"
                                    value="2"
                                    :rules="[() => !!radioGroup || 'Campo obrigatório.']"
                                ></v-radio>
                            </v-col>
                            <v-col cols="3"></v-col>
                        </v-row>
                    </v-radio-group>
                    <p class="text-center" >
                        Por favor, insira uma justificativa explicando o motivo desta ação.
                    </p>
                    <v-row dense class="ma-0">
                        <v-col cols="12" class="px-3">
                            <v-textarea
                            v-model="justificativaRepetirNota"
                            id="justificativaRepetirNota"
                            outlined
                            rows="2"
                            required
                            :rules="[() => !!justificativaRepetirNota || 'Campo obrigatório.']"
                            name="justificativaRepetirNota"
                            label="Justificativa"
                            ></v-textarea>
                        </v-col>      
                    </v-row>

                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="closeDialogRepetirNota()">Fechar</v-btn>
                    <v-btn  color="primary" text @click="desbloquearRepetirNota()">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAlertRepetirNota" width="580">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlertRepetirNota = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess =false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { getGratificacoes } from '../api/gratificacao';
    import { getCodigosAfastamentos } from '../api/situacao_afastamento';
    import { getServidoresAfastados, getTotalServidoresAfastados, getDetalheAfastamentoServidor, gerarExtracaoServidorAfastamento } from '../api/afastamento_servidor';
    import { createDesbloquearRepetirNota } from '../api/gerenciar_afastamento_servidor';

    import AfastamentoServidorDialog from '../components/AfastamentoServidorDialog.vue';
    import LiberarBloquearAvaliacaoDialog from '../components/LiberarBloquearAvaliacaoDialog.vue';
    import { getCiclosVigentes } from '../api/ciclo';

    export default {
        name: 'AfastamentoServidorListar',
        components: {
            AfastamentoServidorDialog,
            LiberarBloquearAvaliacaoDialog
        },
        data() {
            return {
                gratificacoes: [],
                codigosAfastamentos: [],
                headers: [
                    { text: 'CPF', value: 'nr_cpf', sortable: false ,width: '130px'},
                    { text: 'SIAPE', value: 'nr_siape', sortable: false},
                    { text: 'NOME', value: 'nm_servidor', sortable: false },
                    { text: 'GRATIFICAÇÃO', value: 'sg_gratificacao', sortable: false },
                    { text: 'EMAIL', value: 'ds_email_intitucional', sortable: false},
                    { text: 'SITUAÇÃO', value: 'situacao', sortable: false},
                    { text: 'AÇÕES', value: 'acao', align: 'center', sortable: false, width: '5px'}
                ],
                pagination: {
                    current: 1,
                    total: 0,
                    page: 1,
                    itemsPerPage: 10
                },
                filtros: { },
                relatorioAfastamentoServidor: [],
                situacoes: [
                    { text: 'Selecione', value: null},
                    { text: 'BLOQUEADO', value: -1},
                    { text: 'NÃO AVALIÁVEL', value: 1},
                    { text: 'REPETIR NOTA', value: 2},
                    { text: 'VERIFICAR AVALIAÇÃO', value: 3},
                    { text: 'VERIFICAR REPETIÇÃO DE NOTA', value: 4},
                    { text: 'NOTA REPETIDA', value: 5}
                ],
                justificativaRepetirNota:'',
                dialogRepetirNota: false,
                dialogAlertRepetirNota: false,
                id_servidor: null,
                dialogSuccess: false,
                dialogMsg:'',
                radioGroup: null,
                last_page: 0
            };
        },
        mounted() {
            getGratificacoes()
                .then(response => {
                    this.gratificacoes = response.data.data;
                    this.gratificacoes = _.orderBy(this.gratificacoes, 'sg_gratificacao');
                    this.gratificacoes.unshift({id_gratificacao: -1, sg_gratificacao: 'Todas'});
            });

            getCodigosAfastamentos()
                .then(response => {
                    this.codigosAfastamentos = response.data.data;
                    this.codigosAfastamentos = _.orderBy(this.codigosAfastamentos, 'id_situacao_afastamento');
                    this.codigosAfastamentos.unshift({id_situacao_afastamento: -1, co_afastamento_siape: 'Todos'});
            });
        },
        methods: { 
            getFiltros(){
                var filtros = [];
                var obj = {};

                if( this.filtros.nm_servidor ) {
                    obj['nm_servidor'] = this.filtros.nm_servidor.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
                }

                if ( this.filtros.nr_cpf ) {
                    obj['nr_cpf'] = this.filtros.nr_cpf.replace(/[-.]/g,'');
                }

                if ( this.filtros.nr_siape) {
                    obj['nr_siape'] = this.filtros.nr_siape;
                }

                if ( this.filtros.id_gratificacao ) {
                    obj['id_gratificacao'] = this.filtros.id_gratificacao;
                }

                if ( this.filtros.id_situacao_afastamento ) {
                    obj['id_situacao_afastamento'] = this.filtros.id_situacao_afastamento;
                }

                if ( this.filtros.situacoes ) {
                    obj['situacoes'] = this.filtros.situacoes;
                }

                filtros.push(obj);
                return filtros;
            },

            gerarRelatorioAfastamentoServidor() {
                var filtros = [];
                filtros = this.getFiltros();

                getCiclosVigentes(this.$store.getters['auth/usuarioAutenticado']).then(response => {
                    if (response.data.data.length > 0) {
                        getServidoresAfastados(this.pagination.current, this.pagination.itemsPerPage, filtros).then(response => { 
                            this.relatorioAfastamentoServidor = response.data.data.data;
                            this.pagination.current = response.data.data.current_page;
                            this.pagination.total = response.data.data.total;
                            this.last_page = response.data.data.last_page;
                        });
                    } else {
                        this.dialogMsg = 'O ciclo não está vigente.';
                        this.dialogAlertRepetirNota = true;
                    }
                });  
            },

            modalDetalheAfastamento(id_servidor) {
                var afastamento;
                getDetalheAfastamentoServidor(id_servidor)
                    .then(response => {
                        afastamento = response.data.data;
                        this.$refs.afastamentoServidorDialog.mostraAfastamentoDialog(afastamento);
                    });
            },

            liberarOuBloquearAvaliacao(st_bloqueado, id_servidor) {
                this.$refs.liberarBloquearAvaliacaoDialog.showDialog(st_bloqueado, id_servidor);
            },

            showDialogRepetirNota(id_servidor) {
                this.dialogRepetirNota = true;
                this.id_servidor = id_servidor;
            },

            closeDialogRepetirNota() {
                this.dialogRepetirNota = false;
                this.justificativaRepetirNota = '';
            },

            desbloquearRepetirNota() {
                
                if(this.justificativaRepetirNota == '') {
                    this.dialogMsg ="Por favor, insira uma justificativa explicando o motivo desta ação.";
                    return this.dialogAlertRepetirNota = true;
                } 

                if(this.radioGroup == null) {
                    this.dialogMsg ="Selecione uma opção.";
                    return this.dialogAlertRepetirNota = true;
                } 
                
                let data = new FormData();

                if (this.radioGroup == 1) {
                    data.append('st_desbloqueado', 1);
                    data.append('st_repetir_nota', 0);
                } else {
                    data.append('st_repetir_nota', 1);
                    data.append('st_desbloqueado', 0);
                }

                data.append('tx_justificativa', this.justificativaRepetirNota);                
                data.append('id_servidor', this.id_servidor);

                createDesbloquearRepetirNota(data).then(response => {
                    this.gerarRelatorioAfastamentoServidor()
                    this.dialogRepetirNota = false;
                    this.dialogMsg = response.data.message;
                    this.dialogSuccess =true;
                    this.radioGroup = null;
                    this.justificativaRepetirNota = '';
                }).catch(erro => {
                    this.dialogMsg = "Erro ao desbloquear e repetir nota";
                    this.dialogAlertRepetirNota = true;
                    console.log(erro);
                });
            },

            exportarAfastamentoServidor() {
                var filtros = [];
                filtros = this.getFiltros();

                gerarExtracaoServidorAfastamento(filtros)
                    .then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'Servidores afastados.xlsx');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    });
            }
        }
    }
</script>
