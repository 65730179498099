<template>
    <div>
        <h1>Reconsideração</h1>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-form class="mt-5" ref="form_ciclo">
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="6" class="mx-0 px-3">
                                <v-select
                                    id="ciclo"
                                    :items="ciclos"
                                    v-model="filtros.id_ciclo"
                                    item-text="nm_ciclo"
                                    item-value="id_ciclo"
                                    label="Ciclo"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    required 
                                    :rules="[() => !!filtros.id_ciclo || 'Campo obrigatório.']"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="nomeServidor" ref="nm_servidor" dense hide-details="auto" label="Nome do avaliado" v-model="filtros.nm_servidor" outlined clearable clear-icon="mdi-close-circle-outline"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="cpfServidor" ref="nr_cpf_servidor" dense hide-details="auto" label="CPF do avaliado" v-model="filtros.nr_cpf_servidor" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline"></v-text-field>
                        </v-col>                        
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="nomeAvaliador" ref="nm_avaliador" dense hide-details="auto" label="Nome do avaliador" v-model="filtros.nm_avaliador" outlined clearable clear-icon="mdi-close-circle-outline"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="cpfAvaliador" ref="nr_cpf_avaliador" dense hide-details="auto" label="CPF do avaliador" v-model="filtros.nr_cpf_avaliador" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline"></v-text-field>
                        </v-col>                        
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="dtEnvio" dense hide-details="auto" label="Data do envio do pedido de reconsideração" type="date" v-model="filtros.dtEnvio" outlined clearable clear-icon="mdi-close-circle-outline"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="situacao"
                                :items="situacoes"
                                v-model="filtros.situacao"
                                label="Situação"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                            <v-btn id="btnFiltrar" color="primary" @click="filtrarReconsideracao()" ref="btnFiltrarGerenciar">
                                <v-icon>mdi-magnify</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>  
        </v-row>
        <br>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="reconsideracoes"
                    :items-per-page="pagination.itemsPerPage"
                    :footer-props="{'items-per-page-options':[30, 50, 100]}"
                    :options.sync="pagination"
                    :server-items-length="pagination.total"
                    @update:options="filtrarReconsideracao"
                    class="elevation-1"
                >
                    <template v-slot:[`item.situacao`]="{ item }">
                        <v-row v-if="!item.situacao">
                            <v-btn class="white--text" color="warning" rounded x-small>
                                Pendente
                            </v-btn>
                        </v-row>
                        <v-row v-else>
                            <v-btn class="white--text" color="success" rounded x-small>
                                Concluída
                            </v-btn>
                        </v-row>
                    </template>

                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row justify="center">
                            <v-tooltip top v-if="item.avaliar">
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnAnalisar" icon small v-bind="attrs" :color="'primary'" v-on="on" :to="{ name: 'reconsideracoes-detalhar', params: { id: item.id_reconsideracao } }">
                                        <v-icon> mdi-account-check </v-icon>
                                    </v-btn>
                                </template>
                                <span>Analisar pedido</span>
                            </v-tooltip>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnVisualizar" icon small v-bind="attrs" :color="'primary'" v-on="on" :to="{ name: 'reconsideracoes-detalhar', params: { id: item.id_reconsideracao } }">
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                </template>
                                <span>Visualizar</span>
                            </v-tooltip>
                            <v-tooltip top v-if="isAdmin">
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnDelete" icon small v-bind="attrs" :color="'error'" v-on="on" @click="showModalExcluir(item.id_reconsideracao)">
                                        <v-icon> mdi-delete-forever </v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir</span>
                            </v-tooltip>
                        </v-row>
                    </template> 
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="pagination.total">
            <v-col cols="10" class="text-right"></v-col>
            <v-col cols="2" class="ma-0 mb-6 pa-2 px-4 d-flex flex-row justify-end align-end text-right elevation-0">
                <v-btn color="success" @click="exportarXls()">
                    <v-icon>mdi-download</v-icon> Exportar para excel
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <h4 class="primary--text text-center py-6">
                    Por favor, insira uma justificativa explicando<br>
                    porque deseja excluir
                 </h4>
                
                <v-card-text>
                    <v-container>
                        <v-row dense class="ma-0">
                            <v-col cols="12" class="mx-0 px-3">
                                <v-textarea
                                v-model="justificativa"
                                id="justificativa"
                                outlined
                                rows="2"
                                name="justificativa"
                                label="Justificativa"
                                ></v-textarea>
                            </v-col>      
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogDelete = false">Fechar</v-btn>
                <v-btn color="red darken-1"  text @click="excluir()">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAlert" width="580">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{dialogMsg}}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                Feito com sucesso.
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { getReconsideracoes,extracaoReconsideracao, excluirReconsideracao } from '../api/reconsideracao';
    import { getAllCiclos } from '../api/ciclo';

    export default {
        name: 'ReconsideracoesListar',
        data() {
            return {
                reconsideracoes: [],
                ciclos: [],
                filtros: { },
                headers: [
                    { text: 'Nome do avaliado', value: 'nm_servidor', sortable: false},
                    { text: 'CPF do avaliado', value: 'nr_cpf', sortable: false, width: '130px'},
                    { text: 'Data do envio do pedido de reconsideração', value: 'dt_envio', sortable: false,  width: '100px'},
                    { text: 'Nome do avaliador', value: 'nm_avaliador', sortable: false},
                    { text: 'CPF do avaliador', value: 'cpf_avaliador', sortable: false, width: '130px'},
                    { text: 'Situação', value: 'situacao' , sortable: false},
                    { text: 'Ações', value: 'acao', align: 'center', sortable: false}
                ],
                pagination: {
                    current: 1,
                    total: 0,
                    page: 1,
                    itemsPerPage: 30
                },

                situacoes: [
                    { text: 'Selecione', value: null},
                    { text: 'Pendente', value: 1},
                    { text: 'Concluído', value: 2}
                ],
                dtEnvio: false,
                dialogDelete: false,
                justificativa: '',
                id_reconsideracao: null,
                dialogAlert: false,
                dialogMsg: '',
                isAdmin: false,
                dialogSuccess: false
            };
        },

        created() {
            getReconsideracoes().then(response => {
                this.reconsideracoes = response.data.data;
            });

            getAllCiclos().then(response => { 
                this.ciclos = response.data.data; 
            });

            if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") >= 0) {
                this.isAdmin = true;
            }
        },

        methods: {
            getFiltros() {
                var filtros = [];
                var obj = {};

                if ( this.filtros.id_ciclo ) {
                    obj['id_ciclo'] = this.filtros.id_ciclo;
                }

                if( this.filtros.nm_servidor ) {
                    obj['nm_servidor'] = this.filtros.nm_servidor.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
                }

                if ( this.filtros.nr_cpf_servidor ) {
                    obj['nr_cpf_servidor'] = this.filtros.nr_cpf_servidor.replace(/[-.]/g,'');
                }

                if( this.filtros.nm_avaliador ) {
                    obj['nm_avaliador'] = this.filtros.nm_avaliador.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
                }

                if ( this.filtros.nr_cpf_avaliador ) {
                    obj['nr_cpf_avaliador'] = this.filtros.nr_cpf_avaliador.replace(/[-.]/g,'');
                }

                if ( this.filtros.dtEnvio ) {
                    obj['dt_envio'] = this.filtros.dtEnvio;
                }

                if ( this.filtros.situacao ) {
                    obj['situacao'] = this.filtros.situacao;
                }

                filtros.push(obj);
                return filtros;
            },

            filtrarReconsideracao() {
                if ( this.$refs['form_ciclo'].validate() ) {
                    var filtros = [];
                    filtros = this.getFiltros();

                    getReconsideracoes(this.pagination.page, this.pagination.itemsPerPage, filtros).then(response => { 
                        this.reconsideracoes = response.data.data.reconsideracao;

                        this.pagination.total = response.data.data.total;
                        if ( this.pagination.total < 30 ) {
                            this.pagination.page = 1;
                        }
                    });
                }
            },

            exportarXls() {
                var filtros = [];
                filtros = this.getFiltros();

                extracaoReconsideracao(filtros).then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'Reconsiderações.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
            },

            showModalExcluir(id_reconsideracao) {
                this.id_reconsideracao = id_reconsideracao;
                this.justificativa = '';
                this.dialogDelete = true;
            },

            excluir() {
                if (this.justificativa == '' || this.justificativa.length < 3) {
                    this.dialogMsg = 'Justificativa inválida.';
                    return this.dialogAlert = true;
                }

                let data = new FormData();
                data.append('ds_justificativa_delete', this.justificativa);
                data.append('id_admin_excludente', this.$store.getters['auth/usuarioAutenticado']);

                excluirReconsideracao(this.id_reconsideracao, data).then(response => {
                    this.filtrarReconsideracao();
                    this.dialogSuccess =true;
                    this.dialogDelete = false;
                }).catch(erro => {
                    this.dialogMsg = "Erro ao deletar.";
                    this.dialogAlert = true;
                });
            }
        }
    } 
</script>
