<template>
<v-dialog v-model="isVisible" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
        <v-card-title class="mb-2">
            <span class="text-h5">Definir Chefia Imediata Substituta</span>
        </v-card-title>
        <v-card-text>
            <v-row dense class="mx-2 mt-2 elevation-0">
                <v-col cols="3">
                    <v-form class="pa-0" ref="form_servidor_siape_OU_cpf" v-on:submit.prevent dense>
                        <v-text-field v-if="isVisible" ref="siape" dense hide-details="auto" label="SIAPE/CPF" maxlength="11" v-model="campo_siape_OU_cpf" @click:append="buscaServidorPorSIAPEouCPF()" @keyup.enter="buscaServidorPorSIAPEouCPF()" append-icon="mdi-magnify" required @keypress.native="soNumero($event)" outlined :rules="rules.campoSiapeOuCPF"></v-text-field>
                    </v-form>
                </v-col>
                <v-col cols="2" class="ml-5">
                    <v-btn color="primary" @click="buscaServidorPorSIAPEouCPF()">
                        <v-icon>mdi-magnify</v-icon> Buscar
                    </v-btn>
                </v-col>
                <v-col cols="4" class="mx-0">
                    <h4 class="mx-2" v-if="!validations.campo_siape_OU_cpf"> Insira 7 dígitos para consultar por SIAPE ou 11 dígitos para buscar por CPF.</h4>
                </v-col>
            </v-row>
            <v-row dense v-if="erroBuscaSiapeAlertShow" class="mx-2 mt-3 elevation-0">
                <v-col cols="12">
                    <v-alert dense outlined type="error" class="mb-1">
                        {{erroBuscaSiapeMessage}}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row dense v-if="servidorInabilitadoBuscaSiapeAlertShow" class="mx-2 mt-3 elevation-0">
                <v-col cols="12">
                    <v-alert dense outlined type="error" class="mb-1">
                        Servidor não faz mais parte do quadro de servidores ativos do ME.
                    </v-alert>
                </v-col>
            </v-row>
            <v-row class="my-5">
                <v-divider></v-divider>
            </v-row>
            <v-row dense class="ma-2 pa-0">
                <v-col cols="2" class="mx-0">
                    <v-form class="pa-0" ref="form_servidor_siape" v-on:submit.prevent>
                        <v-text-field v-if="isVisible" ref="siape" dense hide-details label="SIAPE" v-model="servidor.nr_siape" v-mask="'#######'" required readonly>
                        </v-text-field>
                    </v-form>
                </v-col>
                <v-col cols="2" class="mx-0">
                    <v-form class="pa-0" ref="form_servidor_cpf" v-on:submit.prevent>
                        <v-text-field v-if="isVisible" dense hide-details label="CPF" v-model="servidor.nr_cpf" v-mask="'###.###.###-##'" required readonly> </v-text-field>
                    </v-form>
                </v-col>
                <v-col cols="8" class="mx-0">
                    <v-text-field v-if="isVisible" dense hide-details label="Nome" v-model="servidor.nm_servidor" required readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row dense class="ma-2 pa-0">
                <v-col cols="12" class="mx-0">
                    <v-text-field v-if="isVisible" dense hide-details label="Unidade de exercício" v-model="servidor.unidade_exercicio && servidor.unidade_exercicio.nm_unidade" required readonly></v-text-field>
                </v-col>
            </v-row>
            <!-- <v-alert dense outlined type="info" class="mb-1"> -->
            <!--
            <v-row dense v-if="definirChefiaImediataSubst_alertInfoMessage_show" class="mx-5 mt-3  mb-2 elevation-0">
                <v-col cols="12">
                    <v-alert dense outlined type="error" class="mb-2">
                        <span v-html="definirChefiaImediataSubst_alertInfoMessage"></span>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row dense v-if="definirChefiaImediataSubst_alertInfoMessage_moverServidor_show" class="mx-5 mt-3  mb-2 elevation-0">
                <v-col cols="12">
                    <v-alert dense outlined type="warning" class="mb-2">
                        <span v-html="definirChefiaImediataSubst_alertInfoMessage_moverServidor"></span>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row dense v-if="definirChefiaImediataSubst_alertInfoMessage_chefeSubPlanos_show" class="mx-5 mt-3 mb-2 elevation-0">
                <v-col cols="12">
                    <v-alert dense outlined type="warning" class="mb-2">
                        <span v-html="definirChefiaImediataSubst_alertInfoMessage_chefeSubPlanos"></span>
                    </v-alert>
                </v-col>
            </v-row>
            -->
            <v-divider></v-divider>
            <v-row dense v-if="definirChefiaImediataSubst_alertInfoMessage_regrasPermitemServidorSubst_show" class="mx-5 mt-3 mb-3 elevation-0">
                <v-col cols="12">
                    <v-alert dense outlined type="info" class="mt-2 mb-2">
                        <span v-html="definirChefiaImediataSubst_alertInfoMessage_regrasPermitemServidorSubst"></span>
                    </v-alert>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
            <v-spacer></v-spacer>
            <v-btn color="default" @click="toggleVisible()">
                Cancelar
            </v-btn>
            <v-btn :disabled="addButtonDisabled" color="success" @click="adicionarChefiaImediataSubst();toggleVisible();">
                Adicionar
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    getServidorBySiape,
    getServidorByCPF
} from '../api/servidor';

import {
    getEquipeTrabalhoBySiapeCiclo,
    getEquipeTrabalhoByCPFCiclo,
    createEquipeTrabalho,
    deleteEquipeTrabalho,
    updateEquipeTrabalho
} from '../api/equipe_trabalho';

export default {
    name: 'BuscaServidorSiapeCPFdialogDefinirChefiaImediataSubst',
    props: ['visible', 'plano_trabalho'],
    data() {
        return {
            campo_siape_OU_cpf: '',
            rules: {
                campoSiapeOuCPF: [
                    () => !!this.campo_siape_OU_cpf || 'Preencha com um SIAPE ou CPF.',
                ]
            },
            validations: {
                campo_siape_OU_cpf: true,
            },
            isVisible: false,
            servidor: {},
            erroBuscaSiapeAlertShow: false,
            erroBuscaSiapeMessage: '',
            definirChefiaImediataSubst_alertInfoMessage_show: false,
            definirChefiaImediataSubst_alertInfoMessage: 'Servidor não pode ser Chefe Substituto desta unidade.',
            definirChefiaImediataSubst_alertInfoMessage_moverServidor_show: false,
            definirChefiaImediataSubst_alertInfoMessage_moverServidor: 'Servidor pertence a outra equipe. Para atribuir como chefe substituto, primeiro adicione a esta equipe de trabalho clicando em ADICIONAR MEMBRO. Clique aqui para visializar a atual equipe do servidor <strong>' + 'nome da equipe de trabalho (unidade)' + '</strong>.',
            definirChefiaImediataSubst_alertInfoMessage_chefeSubPlanos_show: false,
            definirChefiaImediataSubst_alertInfoMessage_chefeSubPlanos: 'Servidor não é chefe de unidades do subplano ',
            addButtonDisabled: true,
            definirChefiaImediataSubst_alertInfoMessage_regrasPermitemServidorSubst_show: true,
            definirChefiaImediataSubst_alertInfoMessage_regrasPermitemServidorSubst: '<strong>IMPORTANTE!</strong> ' +
                '<br>Para ser atribuído como chefe substituto, o servidor precisa ser membro da equipe ou chefe de unidade subordinada (subplanos).' +
                ' Caso o servidor não atenda a um dos critérios, não será possível atribuir.',
            //+'Caso seja chefe substituto de outra unidade, ao ser atribuído como ' +
            servidorInabilitadoBuscaSiapeAlertShow: false,
        }
    },
    created() {},
    methods: {
        soNumero(e) {
            let keyCode = (e.keyCode ? e.keyCode : e.which);
            if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
                e.preventDefault();
            }
        },
        toggleVisible() {
            this.isVisible = !this.isVisible;
            this.clearCampos();
        },
        clearCampos() {
            this.servidor = {};
            this.clearAlerts();
            this.campo_siape_OU_cpf = '';
            this.erroBuscaSiapeMessage = '';
        },
        clearAlerts() {
            this.erroBuscaSiapeAlertShow = false;
            this.definirChefiaImediataSubst_alertInfoMessage_show = false;
            this.definirChefiaImediataSubst_alertInfoMessage_moverServidor_show = false;
            this.definirChefiaImediataSubst_alertInfoMessage_regrasPermitemServidorSubst_show = true;
            this.definirChefiaImediataSubst_alertInfoMessage_chefeSubPlanos_show = false;
            this.servidorInabilitadoBuscaSiapeAlertShow = false;
            this.addButtonDisabled = true;
        },
        buscaServidorPorSIAPEouCPF() {
            this.clearAlerts();
            if (this.$refs['form_servidor_siape_OU_cpf'].validate() && this.campo_siape_OU_cpf) {
                this.validations.campo_siape_OU_cpf = true
                if (this.campo_siape_OU_cpf.length === 11) {
                    let cpf = this.campo_siape_OU_cpf;
                    getServidorByCPF(cpf).then(response => {
                        this.servidor = response.data.data;
                        this.erroBuscaSiapeAlertShow = false;
                        if (!this.servidor.deleted_at){
                            this.buscaEquipeTrabalhoPorCPF(this.servidor);
                        } else {
                            this.servidorInabilitadoBuscaSiapeAlertShow = true
                        }
                    }).catch(erro => {
                        this.erroBuscaSiapeAlertShow = true
                        this.erroBuscaSiapeMessage = (erro.toString().indexOf('404') > -1) ? 'Não foi possível localizar servidor com CPF ' + this.campo_siape_OU_cpf + '' : erro;
                        this.servidor = {}
                    });
                } else if (this.campo_siape_OU_cpf.length === 7) {
                    let siape = this.campo_siape_OU_cpf;
                    getServidorBySiape(siape).then(response => {
                        this.servidor = response.data.data;
                        this.erroBuscaSiapeAlertShow = false;
                        if (!this.servidor.deleted_at){
                            this.buscaEquipeTrabalhoPorSiape(this.servidor)
                        } else {
                            this.servidorInabilitadoBuscaSiapeAlertShow = true
                        }
                    }).catch(erro => {
                        this.erroBuscaSiapeAlertShow = true
                        this.erroBuscaSiapeMessage = (erro.toString().indexOf('404') > -1) ? 'Não foi possível localizar servidor com SIAPE ' + this.campo_siape_OU_cpf + '' : erro;
                        this.servidor = {}
                    });
                } else {
                    this.validations.campo_siape_OU_cpf = false
                    this.servidor = {}
                }
            }
        },

        buscaEquipeTrabalhoPorSiape(servidor) {
            getEquipeTrabalhoBySiapeCiclo(servidor.nr_siape, this.plano_trabalho.ciclo.id_ciclo).then(
                response => {
                    this.servidor = response.data.data;
                    const chefiasSubPlanos_ids = []
                    this.plano_trabalho.planos_filhos.map(planFilho => {
                        if (planFilho.chefia) {
                            if (planFilho.chefia.id) chefiasSubPlanos_ids.push(planFilho.chefia.id)
                        }
                    })
                    if (!this.servidor.equipes_trabalho.length && chefiasSubPlanos_ids.includes(this.servidor.id_servidor)) {
                        this.addButtonDisabled = false;
                    } else if (this.servidor.equipes_trabalho.length) {
                        if (this.servidor.equipes_trabalho[0].id_plano_trabalho === this.plano_trabalho.id_plano_trabalho ||
                            chefiasSubPlanos_ids.includes(this.servidor.id_servidor)) {
                            if (this.servidor.equipes_trabalho[0].plano_trabalho.id_substituto === this.servidor.id_servidor) {
                                this.definirChefiaImediataSubst_alertInfoMessage_chefeSubstOutraUnidade
                                this.definirChefiaImediataSubst_alertInfoMessage = 'Servidor é membro (Chefe Substituto) no plano de trabalho <strong>' +
                                    this.servidor.equipes_trabalho[0].plano_trabalho.nm_unidade + '</strong>. Ao atribuir como chefe substituto, será removida a chefia substituta anterior.';
                                this.definirChefiaImediataSubst_alertInfoMessage_show = true;
                            }
                            this.addButtonDisabled = false;
                        } else {
                            if (this.servidor.equipes_trabalho[0].id_plano_trabalho !== this.plano_trabalho.id_plano_trabalho) {
                                this.definirChefiaImediataSubst_alertInfoMessage_moverServidor = 'Servidor pertence à  equipe ' +
                                    '<strong>' + this.servidor.equipes_trabalho[0].plano_trabalho.nm_unidade + '</strong>';
                                this.definirChefiaImediataSubst_alertInfoMessage_moverServidor_show = true
                            }
                            if (!chefiasSubPlanos_ids.includes(this.servidor.id_servidor)) {
                                this.definirChefiaImediataSubst_alertInfoMessage_chefeSubPlanos = 'Servidor não é chefe de unidade subordinada (subplano). ';
                                this.definirChefiaImediataSubst_alertInfoMessage_chefeSubPlanos_show = true
                            }
                            this.definirChefiaImediataSubst_alertInfoMessage_show = true;
                            this.addButtonDisabled = true;
                        }
                    } else {
                        this.definirChefiaImediataSubst_alertInfoMessage_show = true;
                        this.addButtonDisabled = true;
                    }
                },
                error => {
                    this.addButtonDisabled = true;
                    this.erroBuscaSiapeAlertShow = true;
                    this.erroBuscaSiapeMessage = 'Não foi possível localizar o servidor informado. ' + error;
                }
            );
        },
        buscaEquipeTrabalhoPorCPF(servidor) {
            getEquipeTrabalhoByCPFCiclo(servidor.nr_cpf, this.plano_trabalho.ciclo.id_ciclo).then(
                response => {
                    /*
                    this.servidor = response.data.data;
                    if (this.servidor.equipes_trabalho.length) {
                        if (this.servidor.equipes_trabalho[0].plano_trabalho.id_substituto === this.servidor.id_servidor) {
                            this.definirChefiaImediataSubst_alertInfoMessage = 'Servidor é membro (Chefe Imediato) no plano de trabalho <strong>' + this.servidor.equipes_trabalho[0].plano_trabalho.nm_unidade + '</strong>.';
                            this.addButtonDisabled = false;
                            this.definirChefiaImediataSubst_alertInfoMessage_show = true;
                        } else if (this.servidor.equipes_trabalho[0].id_plano_trabalho === this.plano_trabalho.id_plano_trabalho) {
                            //this.definirChefiaImediata_alertInfoMessage = 'Servidor já pertence ao plano de trabalho <strong>' + this.servidor.equipes_trabalho[0].plano_trabalho.nm_unidade + '</strong>.';
                            this.addButtonDisabled = false;
                        } else {
                            this.definirChefiaImediataSubst_alertInfoMessage_moverServidor = 'Servidor pertence à  equipe ' +
                                '<strong>' + this.servidor.equipes_trabalho[0].plano_trabalho.nm_unidade + '</strong>'
                            this.addButtonDisabled = true;
                            this.definirChefiaImediataSubst_alertInfoMessage_show = true;
                        }
                    } else {
                        this.addButtonDisabled = false;
                    }
                },
                */
                    this.servidor = response.data.data;
                    const chefiasSubPlanos_ids = []
                    this.plano_trabalho.planos_filhos.map(planFilho => {
                        if (planFilho.chefia) {
                            if (planFilho.chefia.id) chefiasSubPlanos_ids.push(planFilho.chefia.id)
                        }
                    })
                    if (!this.servidor.equipes_trabalho.length && chefiasSubPlanos_ids.includes(this.servidor.id_servidor)) {
                        this.addButtonDisabled = false;
                    } else if (this.servidor.equipes_trabalho.length) {
                        if (this.servidor.equipes_trabalho[0].id_plano_trabalho === this.plano_trabalho.id_plano_trabalho ||
                            chefiasSubPlanos_ids.includes(this.servidor.id_servidor)) {
                            if (this.servidor.equipes_trabalho[0].plano_trabalho.id_substituto === this.servidor.id_servidor) {
                                this.definirChefiaImediataSubst_alertInfoMessage_chefeSubstOutraUnidade
                                this.definirChefiaImediataSubst_alertInfoMessage = 'Servidor é membro (Chefe Substituto) no plano de trabalho <strong>' +
                                    this.servidor.equipes_trabalho[0].plano_trabalho.nm_unidade + '</strong>. Ao atribuir como chefe substituto, será removida a chefia substituta anterior.';
                                this.definirChefiaImediataSubst_alertInfoMessage_show = true;
                            }
                            this.addButtonDisabled = false;
                        } else {
                            if (this.servidor.equipes_trabalho[0].id_plano_trabalho !== this.plano_trabalho.id_plano_trabalho) {
                                this.definirChefiaImediataSubst_alertInfoMessage_moverServidor = 'Servidor pertence à  equipe ' +
                                    '<strong>' + this.servidor.equipes_trabalho[0].plano_trabalho.nm_unidade + '</strong>';
                                this.definirChefiaImediataSubst_alertInfoMessage_moverServidor_show = true
                            }
                            if (!chefiasSubPlanos_ids.includes(this.servidor.id_servidor)) {
                                this.definirChefiaImediataSubst_alertInfoMessage_chefeSubPlanos = 'Servidor não é chefe de unidade subordinada (subplano). ';
                                this.definirChefiaImediataSubst_alertInfoMessage_chefeSubPlanos_show = true
                            }
                            this.definirChefiaImediataSubst_alertInfoMessage_show = true;
                            this.addButtonDisabled = true;
                        }
                    } else {
                        this.definirChefiaImediataSubst_alertInfoMessage_show = true;
                        this.addButtonDisabled = true;
                    }
                },
                error => {
                    this.addButtonDisabled = true;
                    this.erroBuscaSiapeAlertShow = true;
                    this.erroBuscaSiapeMessage = 'Não foi possível localizar o servidor informado. ' + error;
                }
            );
        },
        adicionarChefiaImediataSubst() {
            this.emitServidorToParent()
        },
        emitServidorToParent() {
            this.$emit('result:servidor', this.servidor, 'CHEFIA_IMEDIATA_SUBSTITUTA')
        }
    }
}
</script>
