<template>
    <div>
        <h1>Extração de dados</h1>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-row dense class="ma-2 pa-0">
                        <v-col cols="12">
                            <v-row dense class="ma-2 pa-0">
                                <v-col cols="4" class="mx-0">
                                    <v-select
                                        v-model="selectedCiclo"
                                        :items="ciclos"
                                        item-text="nm_ciclo"
                                        item-value="id_ciclo"
                                        label="Selecione o ciclo"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row dense class="ma-2 pa-0">
                                <v-col cols="12">
                                    <v-btn color="primary" @click="extrairDados()" :disabled="!selectedCiclo">
                                        <v-icon>mdi-download</v-icon> Extrair dados
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>


<script>
    import { getServidores } from '../api/servidor';
    import { getCiclos } from '../api/ciclo';
    import { gerarExtracao } from '../api/extracao';

    export default {
        name: 'ServidoresListar',
        data() {
            return {
                ciclos: [],
                selectedCiclo: null
            };
        },
        created() {
            getCiclos()
                .then(response => {
                    this.ciclos = response.data.data;
                });
        },
        methods: {
            extrairDados() {
                gerarExtracao(this.selectedCiclo)
                    .then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'extracao-avalia.xlsx');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    });
            }
        }
    }
</script>