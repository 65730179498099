<template>
<div>
    <h1 class="mx-0">
        Resultado da Avaliação de Desempenho <span v-if="ciclo">{{ciclo.nm_ciclo}}</span>
    </h1>
    <div>
        <h3 class="pa-5 my-2 text-h5">Servidor avaliado</h3>
        <v-card>
            <v-container>
                <v-row class="mx-0">
                    <v-col cols="4" class="mx-0">
                        <v-text-field  
                            ref="nome" 
                            dense 
                            hide-details 
                            label="Nome" 
                            v-model="servidor.nome" 
                            required 
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="mx-0">
                        <v-text-field  
                            ref="siape" 
                            dense 
                            hide-details 
                            label="SIAPE" 
                            v-model="servidor.siape" 
                            required 
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col 
                        cols="4" 
                        class="mx-0"
                        v-if="servidor.orgao"
                    >
                        <v-text-field  
                            ref="orgao" 
                            dense 
                            hide-details 
                            label="Órgão" 
                            v-model="servidor.orgao.nm_orgao" 
                            required 
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col 
                        cols="2" 
                        class="mx-0" 
                        v-if="servidor.sg_gratificacao"
                    >
                        <v-text-field  
                            ref="gratificacao" 
                            dense 
                            hide-details 
                            label="Gratificação" 
                            v-model="servidor.sg_gratificacao"
                            required 
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
    <resultadoSemestral v-if="avaliacao_consolidada && avaliacao_consolidada.semestral"></resultadoSemestral>
    <resultadoPadrao v-if="avaliacao_consolidada && avaliacao_consolidada.padrao"></resultadoPadrao>
    <br/>
    <div>
        <v-row>
            <v-col cols="10" class="text-right"></v-col>
            <v-col cols="2" class="ma-0 mb-6 pa-2 px-4 d-flex flex-row justify-end align-end text-right elevation-0">
                <v-btn color="success" @click="generateReport()">
                    <v-icon>mdi-download</v-icon> Gerar PDF
                </v-btn>
            </v-col>
        </v-row>
    </div>
</div>	
</template>

<script>
import resultadoSemestral from './AvaliacoesResultadoSemestral'
import AvaliacoesResultado from './AvaliacoesResultado'

import { getAvaliacaoConsolidadaResultado, gerarResultadoPdf, getAvaliacaoConsolidadaResultadoRelatorio } from '../api/avaliacao_consolidada';
import { getServidor, getServidorByCPF } from '../api/servidor';
import { getCiclo } from '../api/ciclo';

export default {
    components: {
        'resultadoSemestral': resultadoSemestral,
        'resultadoPadrao': AvaliacoesResultado
    },
    data() {
        return {
            ciclo: [],
            servidor: [],
            avaliacaoColetada: false,
            avaliacao_consolidada: null
        }
    },
    created() {
        getCiclo(this.$route.params.idCiclo).then(response => {
            this.ciclo = response.data.data;
        });

        if ( this.$route.params.idCiclo && this.$route.params.idConsulta ) {
            var functionCreated = getAvaliacaoConsolidadaResultadoRelatorio(this.$route.params.idCiclo, this.$route.params.idConsulta, this.$route.params.sglTipo);
        } else {
            var functionCreated = getAvaliacaoConsolidadaResultado(this.$route.params.idCiclo);
        }

        functionCreated.then(this.$route.params.idCiclo).then(response => {
            this.avaliacao_consolidada = response.data.data;

            console.log(response.data.data);

            this.servidor = response.data.data.servidor;
            this.servidor.nome = response.data.data.servidor.nm_servidor;
            this.servidor.siape = response.data.data.servidor.nr_siape;
            if ( response.data.data.servidor.id_gratificacao ) {
                this.servidor.sg_gratificacao = response.data.data.servidor.gratificacao.sg_gratificacao
            }
        });
    },
    methods: {
        generateReport () {
            var filtros = [];
            var obj = {};
            obj['idCiclo'] = this.$route.params.idCiclo;
            if ( this.$route.params.idConsulta ) {
                obj['idConsulta'] = this.$route.params.idConsulta;
                obj['sglTipo'] = this.$route.params.sglTipo;
            }
            filtros.push(obj);

            gerarResultadoPdf(filtros).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'Resultado da avaliação.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
    }
}
</script>
