<template>
    <div>
        <avaliadorAvaliadoVisualizarDialog ref="avaliadorAvaliadoVisualizarDialog"/>
        <h1>Avaliadores/Avaliados externos</h1>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-form class="mt-5" ref="form_ciclo">
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="6" class="mx-0 px-3">
                                <v-select
                                    id="ciclo"
                                    :items="ciclos"
                                    v-model="filtros.id_ciclo"
                                    item-text="nm_ciclo"
                                    item-value="id_ciclo"
                                    label="Ciclo"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    required 
                                    :rules="[() => !!filtros.id_ciclo || 'Campo obrigatório.']"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="4" class="mx-0 px-3">
                            <v-text-field id="nomeAvaliado" ref="nome" dense hide-details="auto" label="Nome do avaliado" v-model="filtros.nm_avaliado" outlined clearable clear-icon="mdi-close-circle-outline" @keyup.enter="getServidoresFiltrados()"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="mx-0 px-3">
                            <v-text-field id="cpfAvaliado" ref="cpf" dense hide-details="auto" label="CPF do avaliado" v-model="filtros.nr_cpf_avaliado" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" @keyup.enter="getServidoresFiltrados()"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="mx-0 px-3">
                            <v-select
                                id="semEquipe"
                                :items="deletados"
                                v-model="filtros.avaliado_deletado"
                                label="Avaliado deletado"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>                        
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="4" class="mx-0 px-3">
                            <v-combobox
                                id="cargo"
                                ref="cargo"
                                :items="cargos"
                                v-model="filtros.nm_cargo"
                                hide-details
                                outlined 
                                dense
                                placeholder="Cargo do avaliado"
                                ></v-combobox>
                        </v-col>
                        <v-col cols="12" md="4" class="mx-0 px-3">
                            <v-combobox
                                id="orgao"
                                ref="orgao"
                                :items="orgaos"
                                v-model="filtros.nm_orgao_requisitante"
                                hide-details
                                outlined 
                                dense
                                placeholder="Órgão requisitante do avaliado"
                                ></v-combobox>
                        </v-col>
                        <v-col cols="12" md="4" class="mx-0 px-3">
                            <v-select
                                id="gratificacao"
                                :items="gratificacoes"
                                v-model="filtros.id_gratificacao"
                                item-value="id_gratificacao"
                                item-text="sg_gratificacao"
                                label="Gratificação do avaliado"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="nomeAvaliador" ref="nome" dense hide-details="auto" label="Nome do avaliador" v-model="filtros.nm_avaliador" outlined clearable clear-icon="mdi-close-circle-outline" @keyup.enter="getServidoresFiltrados()"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="cpfAvaliador" ref="cpf" dense hide-details="auto" label="CPF do avaliador" v-model="filtros.nr_cpf_avaliador" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" @keyup.enter="getServidoresFiltrados()"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                            <router-link :to="{ name: 'formulario-cedidos-requisitados'}" style="text-decoration: none">
                                <v-btn class="success mr-3">
                                    <v-icon>mdi-plus</v-icon> Cadastrar
                                </v-btn>
                            </router-link>
                            <v-btn id="btnFiltrar" color="primary" @click="gerarRelatorioAvaliadoresAvaliados()">
                                <v-icon>mdi-magnify</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <br />
        <v-row v-show="tableResult">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="relatorioAvaliadorAvaliado"
                    :items-per-page="pagination.itemsPerPage"
                    :footer-props="{'items-per-page-options':[30, 50, 100]}"
                    :options.sync="pagination" 
                    @update:options="gerarRelatorioAvaliadoresAvaliados"
                    :server-items-length="pagination.total"
                    class="elevation-1"
                >
                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row justify="center">
                            <v-tooltip top v-if="item.deleted_at">
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" @click="() => modalJustificativa(item.ds_justificativa, item.deleted_at, item.id_servidor_excludente)">
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                </template>
                                <span>Justificativa</span>
                            </v-tooltip>
                                       
                            <v-menu bottom left v-else>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn fab dark x-small color="blue-grey" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-tooltip top>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" @click="() => modalVisualizar(item.id_equipe_trabalho_externo)">
                                                        <v-icon> mdi-eye </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Visualizar</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn v-if="!item.avaliacao" id="btnEditar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'editarEquipeTrabalhoExterno', params: { id_equipe_trabalho_externo: item.id_equipe_trabalho_externo } }">
                                                        <v-icon> mdi-pencil </v-icon>
                                                    </v-btn>
                                                    <v-btn v-else id="btnNaoEditar" icon small :color="'primary'" v-bind="attrs" v-on="on" @click="() => modalNaoEditar()">
                                                        <v-icon> mdi-pencil </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn id="btnExcluir" icon small :color="'primary'" v-bind="attrs" v-on="on" @click="() => modalDelete(item)">
                                                        <v-icon> mdi-delete-outline </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Excluir</span>
                                            </v-tooltip>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <h4 v-if="deletado" class="primary--text text-center py-6">Justificativa da exclusão</h4>
                <h4 v-else class="primary--text text-center py-6">
                    Por favor, insira uma justificativa explicando<br>
                    porque deseja excluir esse registro
                 </h4>
                
                <v-card-text>
                    <v-container>
                        <v-row v-if="deletado" dense class="ma-0">
                            <v-col cols="12" class="mx-0 px-3 text-center">
                                <strong>Justificativa: </strong>{{justificativa}}<br>
                                <strong>Data de exclusão: </strong>{{dataExclusao}}<br><br>
                                <v-card
                                    class="mx-auto grey--text"
                                    max-width="344"
                                    outlined
                                >
                                    <strong>Dados do servidor que realizou a exclusão </strong><br>
                                    <strong>Nome: </strong>{{dadosExcludente.nome?dadosExcludente.nome:''}}<br>
                                    <strong>CPF: </strong>{{dadosExcludente.cpf?dadosExcludente.cpf:''}}<br>
                                    <strong>SIAPE: </strong>{{dadosExcludente.siape?dadosExcludente.siape:''}}<br>
                                </v-card>
                                
                            </v-col>  
                        </v-row>
                        <v-row v-else dense class="ma-0">
                            <v-col cols="12" class="mx-0 px-3">
                                <v-textarea
                                v-model="justificativa"
                                id="justificativa"
                                outlined
                                rows="2"
                                name="justificativa"
                                label="Justificativa"
                                ></v-textarea>
                            </v-col>      
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="fecharModal">Fechar</v-btn>
                <v-btn color="red darken-1" v-if="!deletado"  text @click="deletarAvaliadorAvaliado">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                Feito com sucesso.
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAlert" width="580">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow" v-if="this.booEditar">Não é possível editar o avaliador/avaliado pois <br>já existe uma avaliação cadastrada para o <br>avaliado.</v-col>
                            <v-col class="grow" v-if="this.booExcluir">Só é possível exclusão se ainda não houver <br>avaliação cadastrada.</v-col>
                            <v-col class="grow" v-if="this.booJustificativa">Necessário preencher justificativa</v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { getAllCiclos } from '../api/ciclo';

    import { getGratificacoes } from '../api/gratificacao';

    import { gerarRelatorioAvaliadoresAvaliadosFiltrados, getTotalRelatorioAvaliadoresAvaliados, getAvaliadorAvaliado, deleteAvaliados, createJustificativa } from '../api/avaliadores_avaliados';

    import { getCargoServidor, getOrgaosRequisitante , getServidor} from '../api/servidor';

    import AvaliadorAvaliadoVisualizarDialog from '../components/AvaliadorAvaliadoVisualizarDialog.vue'

    import moment from 'moment';

    export default {
        name: 'AvaliadoresAvaliadosExternoListar',
        components: {
            AvaliadorAvaliadoVisualizarDialog
        },
        data() {
            return {
                ciclos: [],
                cargos: [],
                orgaos: [],
                gratificacoes: [],

                tableResult: false,
                relatorioAvaliadorAvaliado: [],
                headers: [],
                pagination: {
                    current: 1,
                    total: 0,
                    page: 1,
                    itemsPerPage: 30
                },
                filtros: { },
                dialogDelete:false,
                deleteItem:[],
                dialogSuccess: false,
                dialogAlert: false,
                booEditar: false,
                booExcluir: false,
                booJustificativa: false,
                justificativa: '',
                deletado:false,
                deletados: [
                    { text: 'Sim', value: 1},
                    { text: 'Não', value: 2}
                ],
                dadosExcludente:[],                
            };
        },
        mounted() {
            getAllCiclos()
                .then(response => { 
                    this.ciclos = response.data.data; 
                });

            getCargoServidor()
                .then(response => { 
                    this.cargos = response.data.data; 
                    this.cargos.map(cargo =>{
                        cargo.text = cargo.nm_cargo
                        cargo.value = cargo.nm_cargo
                    })
                    this.cargos.unshift({text: 'Todos', value: -1, id_funcao: -1});
            });

            getOrgaosRequisitante()
                .then(response => { 
                    this.orgaos = response.data.data; 
                    this.orgaos.map(orgao =>{
                        orgao.text = orgao.nm_orgao_requisitante
                        orgao.value = orgao.nm_orgao_requisitante
                    })
                    this.orgaos.unshift({text: 'Todos', value: -1, id_funcao: -1});
            });

            getGratificacoes()
                .then(response => {
                    this.gratificacoes = response.data.data;
                    this.gratificacoes = _.orderBy(this.gratificacoes, 'sg_gratificacao');
                    this.gratificacoes.unshift({id_gratificacao: -1, sg_gratificacao: 'Todas'});
            });

            // getCache('filtrosAvaliadorAvaliado')
            //     .then(response => { 
            //         console.log(response.data.data);
            //         if ( response.data.data ) {
            //             this.cache = true;

            //             this.filtros.id_ciclo = response.data.data.id_ciclo; 
            //             this.filtros.nm_avaliado = response.data.data.nm_avaliado;
            //             this.filtros.nr_cpf_avaliado = response.data.data.nr_cpf_avaliado;
            //             this.filtros.nm_cargo = response.data.data.nm_cargo;
            //             this.filtros.nm_orgao_requisitante = response.data.data.nm_orgao_requisitante;
            //             this.filtros.id_gratificacao = response.data.data.id_gratificacao;
            //             this.filtros.nm_avaliador = response.data.data.nm_avaliador;
            //             this.filtros.nr_cpf_avaliador = response.data.data.nr_cpf_avaliador;

            //             this.gerarRelatorioAvaliadoresAvaliados();
            //         }
            //     }
            // );
        },
        methods: { 
            getFiltros(){
                var filtros = [];
                var obj = {};

                if ( this.filtros.id_ciclo ) {
                    obj['id_ciclo'] = this.filtros.id_ciclo;
                }

                // Filtro nome do avaliado
                if( this.filtros.nm_avaliado ) {
                    obj['nm_avaliado'] = this.filtros.nm_avaliado.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
                }

                // Filtro CPF
                if ( this.filtros.nr_cpf_avaliado ) {
                    obj['nr_cpf_avaliado'] = this.filtros.nr_cpf_avaliado.replace(/[-.]/g,'');
                }

                // Filtro nome avaliador
                if ( this.filtros.nm_avaliador ) {
                    obj['nm_avaliador'] = this.filtros.nm_avaliador.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
                }

                // Filtro CPF avaliador
                if ( this.filtros.nr_cpf_avaliador ) {
                    obj['nr_cpf_avaliador'] = this.filtros.nr_cpf_avaliador.replace(/[-.]/g,'');
                }

                // Filtro cargo
                if ( typeof this.filtros.nm_cargo === 'object' ) {
                    obj['nm_cargo'] = this.filtros.nm_cargo;
                } else {
                    this.$refs.cargo.lazyValue = undefined;
                    this.filtros.nm_cargo = undefined;
                }

                // Filtro orgao requisitante do avaliado
                if ( typeof this.filtros.nm_orgao_requisitante === 'object' ) {
                    obj['nm_orgao_requisitante'] = this.filtros.nm_orgao_requisitante;
                } else {
                    this.$refs.orgao.lazyValue = undefined;
                    this.filtros.nm_orgao_requisitante = undefined;
                }

                // Filtro gratificacao
                if ( this.filtros.id_gratificacao ) {
                    obj['id_gratificacao'] = this.filtros.id_gratificacao;
                }

                // Filtro avaliado deletado
                if ( this.filtros.id_ciclo ) {
                    obj['avaliado_deletado'] = this.filtros.avaliado_deletado;
                }

                filtros.push(obj);

                return filtros;
            },

            gerarRelatorioAvaliadoresAvaliados() {
                if ( this.$refs['form_ciclo'].validate() ) {
                    this.tableResult = true;

                    var filtros = [];
                    filtros = this.getFiltros();

                    gerarRelatorioAvaliadoresAvaliadosFiltrados(this.pagination.page, this.pagination.itemsPerPage, filtros)
                        .then(response => { 
                            this.relatorioAvaliadorAvaliado = response.data.data;
                        });

                    getTotalRelatorioAvaliadoresAvaliados(filtros)
                        .then(response => {
                            this.pagination.total = response.data;
                        });
                }

                this.headers=[
                    { text: 'Nome do avaliado', value: 'nm_avaliado', sortable: false, width: '150px' },
                    { text: 'CPF do avaliado', value: 'nr_cpf_avaliado', sortable: false, width: '130px' },
                    { text: 'Órgão requisitante do avaliado', value: 'nm_orgao_requisitante', sortable: false },
                    { text: 'Cargo do avaliado', value: 'nm_cargo', sortable: false },
                    { text: 'Gratificação do avaliado', value: 'sg_gratificacao', sortable: false, width: '100px' },
                    { text: 'Nome do avaliador', value: 'nm_avaliador', sortable: false, width: '150px' },
                    { text: 'CPF do avaliador', value: 'nr_cpf_avaliador', sortable: false, width: '130px' },
                    { text: 'Ações', value: 'acao', align: 'center', sortable: false, width: '3px' }
                ];
            },

            modalVisualizar(id_equipe_trabalho_externo) {
                var avaliadorAvaliado;
                getAvaliadorAvaliado(id_equipe_trabalho_externo)
                    .then(response => {
                        avaliadorAvaliado = response.data.data;
                        this.$refs.avaliadorAvaliadoVisualizarDialog.mostraAvaliadorAvaliado(avaliadorAvaliado);
                    });
            },

            modalDelete(item) {
                this.dialogDelete = true;
                this.deleteItem = item;
            },

            modalNaoEditar() {
                this.dialogAlert = true;
                this.booExcluir = false;
                this.booEditar = true;
                this.booJustificativa =false;
            },
 
            deletarAvaliadorAvaliado() {
 
                if (this.justificativa == '') {
                    this.booEditar =false;
                    this.booExcluir = false;
                    this.booJustificativa = true;
                    return this.dialogAlert = true;
                }

                createJustificativa(this.deleteItem.id_equipe_trabalho_externo,JSON.stringify(this.justificativa)).then(response => {
                    if (!response.data.data) {
                        this.dialogDelete = false;
                        this.dialogAlert = true;
                        this.booEditar = false;
                        this.booExcluir = true;
                        this.booJustificativa= false;
                    } else {
                        this.gerarRelatorioAvaliadoresAvaliados();
                        this.dialogDelete =false;
                        this.dialogSuccess = true;
                        this.justificativa = '';
                        this.deletado = false;
                    } 
                });
            },

            fecharModal() {
                this.justificativa = '';
                this.deletado = false;
                this.dialogDelete = false;
                this.booJustificativa =false;
            },

             modalJustificativa(ds_justificativa, deleted_at, id_servidor_excludente) {
                this.deletado =true;
                this.dialogDelete = true;
                this.justificativa = ds_justificativa;
                this.dataExclusao = moment(deleted_at, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY");
                getServidor(id_servidor_excludente).then(response => {
                    this.dadosExcludente=  response.data.data;
                });

            },
        }
    }
</script>
