<template>
<div>
    <avaliacaoCadastradaDialog ref="avaliacaoCadastradaDialog" />
    <v-dialog v-model="dialogDetalharServidor" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <v-card color="primary" dark class="mx-auto">
                <v-card-title>
                    <v-icon large left> mdi-account-box-multiple </v-icon>
                    <span class="text-h6 font-weight-light">{{tipoServidor}}</span>
                </v-card-title>
            </v-card>
            <br>
            <v-container>
                <v-row dense v-if="servidor.deleted_at" class="mx-2 mt-n7 elevation-0">
                    <v-col cols="12">
                        <v-alert v-if="servidor.booServidorDuplicado" dense outlined type="error" class="mb-1">
                            Servidor com vínculo alterado no Siape.
                        </v-alert>
                        <v-alert v-else dense outlined type="error" class="mb-1">
                            Servidor não faz mais parte do quadro de servidores ativos do ME.
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="2">
                        <v-textarea rows=1 auto-grow label="SIAPE" dense hide-details="auto" 
                        :value="servidor.siape" readonly> </v-textarea>
                    </v-col>
                    <v-col cols="3">
                        <v-textarea rows=1 auto-grow label="CPF" dense hide-details="auto" 
                        :value="servidor.cpf" readonly> </v-textarea>
                    </v-col>
                    <v-col cols="7">
                        <v-textarea rows=1 auto-grow label="Nome" dense hide-details="auto" 
                        :value="servidor.nome" readonly></v-textarea>
                    </v-col>
                    <v-col cols="2">
                        <v-textarea rows=1 auto-grow label="Telefone" dense hide-details="auto" 
                        :value="servidor.telefone" readonly></v-textarea>
                    </v-col>
                    <v-col cols="5">
                        <v-textarea rows=1 auto-grow label="E-mail Institucional" dense hide-details="auto" 
                        :value="servidor.email_institucional" readonly></v-textarea>
                    </v-col>
                    <v-col cols="5">
                        <v-textarea rows=1 auto-grow label="E-mail Pessoal" dense hide-details="auto" 
                        :value="servidor.email_pessoal" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Gratificação" dense hide-details="auto" 
                        :value="servidor.gratificacao" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Cargo" dense hide-details="auto" 
                        :value="servidor.cargo" readonly></v-textarea>
                    </v-col>
                    <v-col cols="4">
                        <v-textarea rows=1 auto-grow label="Situação" dense hide-details="auto" 
                        :value="servidor.situacao_servidor" readonly></v-textarea>
                    </v-col>
                    <v-col cols="8">
                        <v-textarea rows=1 auto-grow label="Função" dense hide-details="auto" 
                        :value="servidor.funcaoToShow" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Órgão" hide-details="auto" 
                        :value="servidor.orgao" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Órgão Exercício" hide-details="auto" 
                        :value="servidor.unidade_exercicio" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Órgão Requisitante" hide-details="auto" 
                        :value="servidor.nm_orgao_requisitante" readonly></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <label>Planos de trabalho do servidor</label>
                        <v-data-table
                            :headers="headers"
                            :items="listaPlanos"
                            class="elevation-1"
                            hide-default-footer
                        >
                            <template v-slot:[`item.acao`]="{ item }">
                                <v-row justify="center" v-if="item.booVisualizar">
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'planos-trabalho-detalhar', params: { id: item.plano_trabalho.id_plano_trabalho } }">
                                                <v-icon> mdi-eye </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar plano de trabalho</span>
                                    </v-tooltip>
                                </v-row>
                                <v-row justify="center" v-else>
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on">
                                                <v-icon> mdi-eye-off </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Não é possível visualizar o plano</span>
                                    </v-tooltip>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-tooltip top v-if="showBtnGerarAvaliacao && !servidor.deleted_at">
                    <template v-slot:activator="{ on, attrs}">
                        <v-btn color="primary" v-bind="attrs" v-on="on" outlined @click="() => modalAvaliacaoCadastrada(servidor)">
                            Gerar Avaliação
                        </v-btn>
                    </template>
                    <span>Gerar Avaliação</span>
                </v-tooltip>
                                                
                <v-btn color="primary" @click="dialogDetalharServidor = false"> Fechar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import AvaliacaoCadastradaDialog from '../components/AvaliacaoCadastradaDialog.vue';

export default {
    components: {
        AvaliacaoCadastradaDialog
    },
    data() {
        return {
        	tipoServidor: '',
            servidor: {
                siape: '',
                cpf: '',
                nome: '',
                telefone: '',
                email_institucional: '',
                email_pessoal: '',
                email: '',
                gratificacao: '',
                cargo: '',
                situacao_servidor: '',
                funcao: '',
                codigo_funcao: '',
                unidade_exercicio: '',
                deleted_at: null,
                booServidorDuplicado: false
            },
            dialogDetalharServidor: false,

            listaPlanos: [],
            headers: [
                { text: 'Nome do plano', value: 'plano_trabalho.nm_unidade', sortable: false },
                { text: 'Nome do Ciclo', value: 'plano_trabalho.ciclo.nm_ciclo', sortable: false, width: '170px' },
                { text: 'Ações', value: 'acao', align: 'center', sortable: false},
            ],
            showBtnGerarAvaliacao:false
        }
    },
    created() {
        if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") >= 0) {
            this.showBtnGerarAvaliacao = true;
        }
    },
    methods: {
    	mostraServidorDialog(servidor, tipoServidor, deleted_at) {
            this.servidor.id = servidor.id;
        	this.tipoServidor = tipoServidor;
            this.servidor.siape = servidor.siape;
            this.servidor.cpf = servidor.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            this.servidor.nome = servidor.nome;
            this.servidor.telefone = servidor.telefone;
            this.servidor.email_institucional = servidor.email_institucional;
            this.servidor.email_pessoal = servidor.email_pessoal;
            this.servidor.gratificacao = servidor.gratificacao;
            this.servidor.cargo = servidor.cargo;
            this.servidor.situacao_servidor = servidor.situacao_servidor;
            this.servidor.funcao = servidor.funcao;
            this.servidor.codigo_funcao = servidor.codigo_funcao;
            this.servidor.funcaoToShow = (servidor.funcao) ? servidor.funcao + ' - ' + servidor.codigo_funcao : '';
            this.servidor.unidade_exercicio = servidor.unidade_exercicio;
            this.servidor.orgao = servidor.orgao;
            this.servidor.deleted_at = deleted_at;
            this.dialogDetalharServidor = true;

            if ( servidor.nm_orgao_requisitante ) {
                this.servidor.nm_orgao_requisitante = servidor.nm_orgao_requisitante;
            }

            this.listaPlanos = [];
            if ( servidor.listaPlanos ) {
                this.listaPlanos = _.orderBy(servidor.listaPlanos, 'plano_trabalho.ciclo.nm_ciclo', 'desc');
            }

            this.servidor.booServidorDuplicado = servidor.booServidorDuplicado;
        },

        modalAvaliacaoCadastrada(servidor) {
            this.$refs.avaliacaoCadastradaDialog.showDialog(servidor);
        }
    }
}

</script>
