<template>
    <div>
        <h1>Resultados das Avaliações</h1>
        <v-row v-if="resultadosAvaliacoes.length">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="resultadosAvaliacoes"
                    class="elevation-1"
                    hide-default-footer
                >

                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row justify="center">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <!-- <v-btn v-if="item.id_equipe_trabalho" id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'avaliacoes-resultado', params: { id: item.id_equipe_trabalho, tipo: 'i' } }">
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                    <v-btn v-if="item.id_equipe_trabalho_externo" id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'avaliacoes-resultado', params: { id: item.id_equipe_trabalho_externo, tipo: 'e' } }">
                                        <v-icon> mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn v-if="item.id_avaliacao_cadastrada" id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'avaliacoes-resultado', params: { id: item.id_avaliacao_cadastrada, tipo: 'a' } }">
                                        <v-icon> mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn v-if="item.id_servidor_afastado" id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'avaliacoes-resultado', params: { id: item.id_avaliacao_consolidada, tipo: 'af' } }">
                                        <v-icon> mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn v-if="item.id_pontuacao" id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'avaliacoes-resultado', params: { id: item.id_pontuacao, tipo: 'po' } }">
                                        <v-icon> mdi-eye</v-icon>
                                    </v-btn> -->
                                    <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'avaliacoes-resultado', params: { idCiclo: item.id_ciclo } }">
                                        <v-icon> mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Visualizar resultado da avaliação</span>
                            </v-tooltip>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-else dense class="mx-5 mt-3 elevation-0">
            <v-col cols="12">
                <v-alert dense outlined type="info" class="mb-5">
                    <span style="white-space: pre-line">No momento, você não possui resultado de avaliação dos ciclos a partir de 2020. 
                        Caso tenha dúvidas, recorra ao Gestor Setorial ou ao Ponto Focal de sua unidade de avaliação.
                    </span>
                </v-alert>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { getListaResultadosAvaliacoes } from '../api/avaliacao_consolidada';

    export default {
        name: 'AvaliacoesListaResultado',
        data() {
            return {
                resultadosAvaliacoes: [],

                headers: [
                    { text: 'Nome do Ciclo', value: 'nm_ciclo', sortable: false },
                    { text: 'Periodo do ciclo', value: 'periodo_ciclo', sortable: false },
                    { text: 'Ações', value: 'acao', align: 'center', sortable: false},
                ],
                pagination: {
                    current: 1,
                    total: 0,
                    page: 1,
                    itemsPerPage: 30
                },
                filtros: {

                },
            };
        },
        mounted() {
            getListaResultadosAvaliacoes(this.pagination.page, this.pagination.itemsPerPage)
                .then(response => {
                    this.resultadosAvaliacoes = response.data.data;
                    this.resultadosAvaliacoes = _.orderBy(this.resultadosAvaliacoes, 'dtOrdem', 'desc');
                });
        },
    }
</script>
