<template>
    <div>
        <h1>Gerenciar Órgãos</h1>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-2">
                    <v-row dense class="ma-0 pa-2"> 
                        <v-col cols="12" md="12" class="mx-0 px-3">
                            <v-text-field 
                                id="nome" 
                                v-model="inputOrgao.nm_orgao" 
                                ref="nome" 
                                @keyup="uppercase"
                                dense 
                                hide-details="auto" 
                                label="Nome do órgão" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>                  
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="codigo" 
                                v-model="inputOrgao.cd_orgao" 
                                ref="codigo" 
                                dense 
                                hide-details="auto" 
                                label="Código no SIAPE" 
                                v-mask="'#####'" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" md="6" class="mx-0 px-3">
                           <v-text-field 
                                id="sigla" 
                                v-model="inputOrgao.sg_sigla" 
                                @keyup="uppercase"
                                ref="sigla" 
                                dense 
                                hide-details="auto" 
                                label="Sigla do órgão" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="d-flex flex-row justify-end align-end text-right">
                            <v-btn class="success mr-3" @click="salvar">
                                Cadastrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <br>
        <v-card>
            <template>
                <v-data-table
                    :headers="headersOrgaos"
                    :items="orgaos"
                    :items-per-page="5"
                    class="elevation-1"
                >
                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        id="detalharOrgao" 
                                        icon 
                                        class="mr-2"
                                        small 
                                        :color="'primary'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        @click="showDialogDetalharOrgao(item.id_orgao)"
                                    >
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                </template>
                                <span>Detalhar órgão e seus gestores</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        id="editarOrgao" 
                                        icon 
                                        class="mr-2"
                                        small 
                                        :color="'warning'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        @click="showDialogEditarOrgao(item.id_orgao)"
                                    >
                                        <v-icon> mdi-pencil </v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar órgão</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn 
                                        id="btnDelete" 
                                        icon 
                                        small 
                                        v-bind="attrs" 
                                        :color="'error'" 
                                        v-on="on" 
                                        @click="showDialogExcluirOrgao(item.id_orgao)"
                                    >
                                        <v-icon> mdi-delete-forever </v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir órgão</span>
                            </v-tooltip>
                        </v-row>
                    </template>    
                </v-data-table>
            </template>
        </v-card>

         <v-dialog v-model="dialogWarning" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogWarning = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>  

        <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{dialogMsg}}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog> 
        <v-dialog v-model="dialogEditarOrgao" max-width="700px">
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-card-title>
                        <v-icon large left> mdi-pencil </v-icon>
                        <span class="text-h6 font-weight-light">
                            Editar órgão
                        </span>
                    </v-card-title>
                </v-card>

                <v-card-text>
                    <v-container>
                    <v-row dense class="ma-0 pa-2"> 
                        <v-col cols="12" md="12" class="mx-0 px-3">
                            <v-textarea
                                id="editarnome" 
                                v-model="orgao.nm_orgao" 
                                ref="editarnome" 
                                @keyup="uppercase"
                                dense 
                                hide-details="auto" 
                                label="Nome do órgão" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-textarea>
                        </v-col>                  
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="editarcodigo" 
                                v-model="orgao.cd_orgao" 
                                ref="editarcodigo" 
                                dense 
                                hide-details="auto" 
                                label="Código no SIAPE" 
                                v-mask="'#####'" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" md="6" class="mx-0 px-3">
                           <v-text-field 
                                id="editarsigla" 
                                v-model="orgao.sg_sigla" 
                                @keyup="uppercase"
                                ref="editarsigla" 
                                dense 
                                hide-details="auto" 
                                label="Sigla do órgão" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogEditarOrgao = false">Fechar</v-btn>
                <v-btn color="primary darken-1"  text @click="editar()">Editar</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="dialogExcluirOrgao" max-width="500px">
            <v-card>
                <h4 class="primary--text text-center py-6">
                    Tem certeza que deseja excluir esse orgão?
                 </h4>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogExcluirOrgao = false">Fechar</v-btn>
                <v-btn color="red darken-1"  text @click="deletarOrgao()">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog 
            v-model="dialogDetalharOrgao" 
            width="100%" 
            fullscreen 
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dark color="primary">
                <v-btn icon dark>
                    <v-icon>mdi mdi-domain</v-icon>
                </v-btn>
                <v-toolbar-title>Detalhes do Órgão</v-toolbar-title>            
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="dialogDetalharOrgao=false">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
                </v-toolbar>

                <v-container >
                    <v-row dense class="ma-0 pa-2"> 
                        <v-col cols="12" md="12" class="mx-0 px-3">
                            <span class="text-h5">Nome do órgão:</span><br>
                            <span class="">{{orgao.nm_orgao}}</span>
                        </v-col>                  
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <span class="text-h5">Código no SIAPE:</span>
                            <span class="">{{orgao.cd_orgao}}</span>
                        </v-col> 
                        <v-col cols="12" md="6" class="mx-0 px-3">
                           <span class="text-h5">Sigla:</span>
                            <span class="">{{orgao.sg_sigla}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="d-flex flex-row justify-end align-end text-right">
                            
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        id="editarOrgao2" 
                                        fab 
                                        class="mr-4" 
                                        small
                                        :color="'warning'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        @click="showDialogEditarOrgao(orgao.id_orgao)"
                                    >
                                        <v-icon> mdi-pencil </v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar órgão</span>
                            </v-tooltip>
                            
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        id="deletarOrgao2" 
                                        fab 
                                        small
                                        class="mr-4" 
                                        :color="'error'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        @click="showDialogExcluirOrgao(orgao.id_orgao)"
                                    >
                                        <v-icon> mdi-delete-forever</v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir órgão</span>
                            </v-tooltip>
                       
                        </v-col>
                    </v-row>
                    <br>
                    
                    <v-card>                        
                        <template>
                            <v-data-table
                                :headers="headersGestores"
                                :items="gestores"
                                :items-per-page="5"
                                class="elevation-1"
                            >
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title class="grey--text">
                                           Servidores com o perfil de 
                                           <strong>Gestor do Órgão</strong> 
                                           {{orgao.sg_sigla}}
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="success"
                                            dark
                                            class="mb-2"
                                            @click="showDialogAdicionarGestor(orgao.id_orgao)"
                                        >
                                        <v-icon left>
                                            mdi mdi-account-plus
                                        </v-icon>
                                        Adicionar Gestor
                                        </v-btn>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.acao`]="{ item }">
                                    <v-row>
                                        <v-tooltip top>
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn 
                                                    id="btnDeleteGestor" 
                                                    icon 
                                                    small 
                                                    v-bind="attrs" 
                                                    :color="'error'" 
                                                    v-on="on" 
                                                    @click="showDialogExcluirGestor(item.id_gestor_orgao)"
                                                >
                                                    <v-icon> mdi-delete-forever </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Excluir gestor</span>
                                        </v-tooltip>
                                    </v-row>
                                </template>
                            </v-data-table>
                        </template>
                    </v-card>
   
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="dialogDetalharOrgao=false">Fechar</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialogAdicionarGestor" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card-title class="mb-2">
                    <span class="text-h5">Adicionar gestor</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="5">
                            <v-text-field 
                                v-model="siapeBusca" 
                                dense 
                                hide-details 
                                label="SIAPE" 
                                @keyup.enter="buscaServidorPorSiape" 
                                v-mask="'#########'" 
                                required 
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn 
                                color="primary"
                                @click="buscaServidorPorSiape"
                            >
                                <v-icon>mdi-magnify</v-icon> Buscar
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                    <v-row class="my-5">
                        <v-divider></v-divider>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field v-model="siapeBuscaResultado.nr_cpf" dense hide-details label="CPF" required readonly></v-text-field>
                        </v-col>
                        <v-col cols="8" class="mx-0">
                            <v-text-field v-model="siapeBuscaResultado.nm_servidor" dense hide-details label="Nome" required readonly></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="siapeBuscaResultado.ds_email_institucional" dense hide-details label="E-mail institucional"  required readonly></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="siapeBuscaResultado.nr_telefone" dense hide-details label="Telefone" required readonly></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="py-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="default"
                        @click="dialogAdicionarGestor = false"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        @click="salvarGestor"
                    >
                        Adicionar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogExcluirGestor" max-width="500px">
            <v-card>
                <h4 class="primary--text text-center py-6">
                    Tem certeza que deseja excluir?
                 </h4>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogExcluirGestor = false">Fechar</v-btn>
                <v-btn color="red darken-1"  text @click="deletarGestor()">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { salvarOrgao } from '../api/orgao';
import { getOrgaos } from '../api/orgao';
import { getOrgao } from '../api/orgao';
import { editarOrgao } from '../api/orgao';
import { excluirOrgao } from '../api/orgao';
import { getServidorBySiape } from '../api/servidor';
import { salvarGestorOrgao } from '../api/gestor_orgao';
import { getGestores } from '../api/gestor_orgao';
import { getGestorByCPF } from '../api/gestor_orgao';
import { excluirGestorOrgao } from '../api/gestor_orgao';

export default {
    name: 'OrgaosCadastrar',
    data() {
        return {
            inputOrgao: [],
            orgaos: [],
            gestores:[],
            dialogWarning: false,
            dialogSuccess: false,
            dialogMsg: '',
            headersOrgaos: [
                { text: 'Código no SIAPE', value: 'cd_orgao', sortable: false },
                { text: 'Sigla', value: 'sg_sigla', sortable: false },
                { text: 'Nome do órgão', value: 'nm_orgao', sortable: false },
                { text: 'Ações', value: 'acao', sortable: false, width: '150px' },
            ],
            dialogEditarOrgao: false,
            dialogExcluirOrgao: false,
            orgao: [],
            id_orgao: null,
            dialogDetalharOrgao: false,
            headersGestores: [
                { text: 'CPF', value: 'nr_cpf', sortable: false },
                { text: 'Nome do Gestor', value: 'nm_servidor', sortable: false },
                { text: 'E-mail institucional', value: 'ds_email', sortable: false },
                { text: 'Ação', value: 'acao', sortable: false, width: '150px' },
            ],
            dialogAdicionarGestor: false,
            siapeBuscaResultado: [],
            siapeBusca: null,
            gestores: [],
            dialogExcluirGestor: false,
            id_gestor_orgao: null
        }
    },
    created() {
        this.listarOrgaos();
    },
    methods: {
        uppercase() {
            if (this.inputOrgao.nm_orgao) {
                this.inputOrgao.nm_orgao = this.inputOrgao.nm_orgao =null ? null : this.inputOrgao.nm_orgao.toUpperCase();
            }
            if (this.inputOrgao.sg_sigla) {
                this.inputOrgao.sg_sigla = this.inputOrgao.sg_sigla =null ? null : this.inputOrgao.sg_sigla.toUpperCase();
            }
            if (this.orgao.nm_orgao) {
                this.orgao.nm_orgao = this.orgao.nm_orgao =null ? null : this.orgao.nm_orgao.toUpperCase();
            }
            if (this.orgao.sg_sigla) {
                this.orgao.sg_sigla = this.orgao.sg_sigla =null ? null :  this.orgao.sg_sigla.toUpperCase();
            }
        },

        listarOrgaos() {
            getOrgaos().then(response => {
                this.orgaos = response.data.data;
            });
        },

        salvar() {
            if (!this.inputOrgao.nm_orgao) {
                this.dialogMsg = "Necessário ter o nome do órgão";
                return this.dialogWarning = true;
            }

            if (!this.inputOrgao.cd_orgao) {
                this.dialogMsg = "Necessário ter o código";
                return this.dialogWarning = true;
            }

            if (!this.inputOrgao.sg_sigla) {
                this.dialogMsg = "Necessário ter uma sigla";
                return this.dialogWarning = true;
            }

            if (this.inputOrgao.nm_orgao && this.inputOrgao.nm_orgao.length < 3) {
                this.dialogMsg = "Nome deve ter pelo menos 3 caracteres";
                return this.dialogWarning = true;
            }

            if (this.inputOrgao.cd_orgao && this.inputOrgao.cd_orgao.length < 5) {
                this.dialogMsg = "Codigo deve ter pelo menos 5 caracteres";
                return this.dialogWarning = true;
            }
            
            if (this.inputOrgao.sg_sigla && this.inputOrgao.sg_sigla.length < 2) {
                this.dialogMsg = "Sigla deve ter pelo menos 2 caracteres";
                return this.dialogWarning = true;
            }

            getOrgao(this.inputOrgao.cd_orgao).then(response => {
                if (response.data.data.id_orgao) {
                    this.dialogMsg = "Já possui um orgão com esse código";
                    return this.dialogWarning = true;
                } else {
                    let data = new FormData();

                    data.append('nm_orgao', this.inputOrgao.nm_orgao);
                    data.append('cd_orgao', this.inputOrgao.cd_orgao);
                    data.append('sg_sigla', this.inputOrgao.sg_sigla);

                    salvarOrgao(data).then(response => {
                        this.listarOrgaos();
                        this.inputOrgao = [];
                        this.dialogMsg = "Feito com sucesso";
                        this.dialogSuccess = true;
                    }).catch(erro => {
                        this.dialogMsg = "erro ao salvar";
                        this.dialogWarning = true;
                    });
                }
            });
        },

        showDialogEditarOrgao(id_orgao) {
            this.dialogEditarOrgao = true;
            getOrgao(id_orgao).then(response => {
                this.orgao = response.data.data;
                this.orgao.cd_orgao = ("00000" + this.orgao.cd_orgao).slice(-5);
            });
        },
        
        editar() {
            if (!this.orgao.nm_orgao) {
                this.dialogMsg = "Necessário ter o nome do órgão";
                return this.dialogWarning = true;
            }

            if (!this.orgao.cd_orgao) {
                this.dialogMsg = "Necessário ter o código";
                return this.dialogWarning = true;
            }

            if (!this.orgao.sg_sigla) {
                this.dialogMsg = "Necessário ter uma sigla";
                return this.dialogWarning = true;
            }

            if (this.orgao.nm_orgao && this.orgao.nm_orgao.length < 3) {
                this.dialogMsg = "Nome deve ter pelo menos 3 caracteres";
                return this.dialogWarning = true;
            }

            if (this.orgao.cd_orgao && this.orgao.cd_orgao.length < 5) {
                this.dialogMsg = "Codigo deve ter pelo menos 5 caracteres";
                return this.dialogWarning = true;
            }
            
            if (this.orgao.sg_sigla && this.orgao.sg_sigla.length < 2) {
                this.dialogMsg = "Sigla deve ter pelo menos 2 caracteres";
                return this.dialogWarning = true;
            }

            let data = new FormData();

            data.append('id_orgao', this.orgao.id_orgao);
            data.append('nm_orgao', this.orgao.nm_orgao);
            data.append('cd_orgao', this.orgao.cd_orgao);
            data.append('sg_sigla', this.orgao.sg_sigla);

            editarOrgao(data).then(response => {
                this.listarOrgaos();
                this.dialogDetalharOrgao = false;
                this.dialogEditarOrgao =false;
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess = true;
            }).catch(erro => {
                this.dialogMsg = "Erro ao editar";
                this.dialogWarning = true;
            });
        },

        showDialogExcluirOrgao(id) {
            this.dialogExcluirOrgao = true;
            this.id_orgao = id;
        },

        deletarOrgao() {
            let data = new FormData();
            data.append('id_orgao', this.id_orgao);

            excluirOrgao(data).then(response => {
                this.dialogExcluirOrgao = false;
                this.dialogDetalharOrgao = false;
                this.id_orgao = null;
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess =true;
                this.listarOrgaos();
            }).catch(erro => {
                this.dialogMsg = "Erro ao deletar.";
                this.dialogWarning = true;
            });
        },

        showDialogDetalharOrgao(id_orgao) {
            this.dialogDetalharOrgao = true;
            getOrgao(id_orgao).then(response => {
                this.orgao = response.data.data;
                this.orgao.cd_orgao = ("00000" + this.orgao.cd_orgao).slice(-5);
            });
            getGestores(id_orgao).then(response => {
                this.gestores = response.data.data;
            });

        },

        buscaServidorPorSiape() {
            getServidorBySiape(this.siapeBusca).then(
                response => {
                    this.siapeBuscaResultado = response.data.data;
                },
                error => {
                    this.siapeBusca = null;
                    this.siapeBuscaResultado = [];
                    this.dialogMsg = 'Servidor não encontrado';
                    this.dialogWarning = true;
                }
            );
        },

        showDialogAdicionarGestor(id_orgao) {
            this.id_orgao = id_orgao;
            this.dialogAdicionarGestor = true;
            this.siapeBusca = null;
            this.siapeBuscaResultado = [];
        },

        salvarGestor() {
            if (!this.siapeBusca) {
                this.dialogMsg = "Necessário preencher o SIAPE";
                return this.dialogWarning = true;
            }

            if (!this.siapeBuscaResultado.nm_servidor) {
                this.dialogMsg = "Necessário ter informações do servidor";
                return this.dialogWarning = true;
            }
            let gestor = new FormData();

            gestor.append('nr_cpf', this.siapeBuscaResultado.nr_cpf);
            gestor.append('id_orgao', this.id_orgao); 
            
            getGestorByCPF(gestor).then(response => {
                
                if (response.data.data.id_gestor_orgao) {
                    this.dialogMsg = "Servidor já é gestor do órgão";
                    return this.dialogWarning = true;
                } else {
                    let data = new FormData();

                    data.append('id_orgao', this.id_orgao);
                    data.append('id_servidor', this.siapeBuscaResultado.id_servidor);

                    salvarGestorOrgao(data).then(response => {
                        getGestores(this.id_orgao).then(response => {
                            this.gestores = response.data.data;
                        });
                        this.dialogMsg = "Feito com sucesso";
                        this.dialogSuccess = true;
                        this.dialogAdicionarGestor = false;
                        
                    }).catch(erro => {
                        this.dialogMsg = "erro ao salvar";
                        this.dialogWarning = true;
                    });
                }
            });
        },

        showDialogExcluirGestor(id_gestor_orgao) {
            this.dialogExcluirGestor = true;
            this.id_gestor_orgao = id_gestor_orgao;
        },

        deletarGestor() {
            let data = new FormData();
            data.append('id_gestor_orgao', this.id_gestor_orgao);

            excluirGestorOrgao(data).then(response => {
                this.dialogExcluirGestor = false;
                this.dialogMsg = "Feito com sucesso";
                getGestores(this.orgao.id_orgao).then(response => {
                    this.gestores = response.data.data;
                });
                this.dialogSuccess =true;
                this.id_gestor_orgao = null;
            }).catch(erro => {
                this.dialogMsg = "Erro ao deletar.";
                this.dialogWarning = true;
            });
        },
    }
}
</script>