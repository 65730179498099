<template>
    <div>
    <excluirAvaliacoesDialog ref="excluirAvaliacoesDialog" />
    <cadastrarNotaAvaliacaoDialog ref="cadastrarNotaAvaliacaoDialog" />
    <h1>Gerenciar Avaliação</h1>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-form class="mt-5" ref="form_ciclo">
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="ciclo"
                                :items="ciclos"
                                v-model="filtros.id_ciclo"
                                item-text="nm_ciclo"
                                item-value="id_ciclo"
                                label="Ciclo"
                                outlined
                                dense
                                hide-details="auto"
                                required 
                                :rules="[() => !!filtros.id_ciclo || 'Campo obrigatório.']"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="12" md="6" class="mx-0 px-3">
                        <v-text-field 
                            id="nomeServidor" 
                            ref="nm_servidor" 
                            dense 
                            hide-details="auto" 
                            label="Nome" 
                            v-model="filtros.nm_servidor" 
                            outlined 
                            clearable 
                            clear-icon="mdi-close-circle-outline"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="mx-0 px-3">
                        <v-text-field 
                            id="cpfServidor" 
                            ref="nr_cpf_servidor" 
                            dense 
                            hide-details="auto" 
                            label="CPF" 
                            v-model="filtros.nr_cpf_servidor" 
                            v-mask="'###.###.###-##'" 
                            outlined 
                            clearable 
                            clear-icon="mdi-close-circle-outline"
                        ></v-text-field>
                    </v-col>                        
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="12" md="6" class="mx-0 px-3">
                        <v-select
                            id="gratificacao"
                            :items="gratificacoes"
                            v-model="filtros.id_gratificacao"
                            item-value="id_gratificacao"
                            item-text="sg_gratificacao"
                            label="Gratificação"
                            outlined
                            dense
                            hide-details="auto"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="mx-0 px-3">
                        <v-combobox
                            id="orgao"
                            v-model="filtros.orgao"
                            :items="orgaos"
                            hide-details="auto" 
                            dense
                            label="Órgão"
                            outlined
                            :disabled="isGestorOrgao"
                        ></v-combobox>
                    </v-col>                        
                </v-row>
                <v-row>
                    <v-col class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                        <v-btn class="success mr-3" @click="() => modalCadastrarNotaAvaliacao()">
                            <v-icon>mdi-plus</v-icon> Inserir pontuação
                        </v-btn>
                        <v-btn id="btnFiltrar" color="primary" @click="filtrarGerenciarAvaliacao()" ref="btnFiltrarGerenciar">
                            <v-icon>mdi-magnify</v-icon> Filtrar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
    <br>
    <v-row>
        <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="avaliacoesCadastradas"
                :items-per-page="pagination.itemsPerPage"
                :footer-props="{'items-per-page-options':[10, 30, 50]}"
                :options.sync="pagination"
                :server-items-length="pagination.total"
                @update:options="filtrarGerenciarAvaliacao"
                class="elevation-1"
            >

                <template v-slot:[`item.booAvaliadoChefia`]="{ item }">
                    <v-row v-if="!item.booAvaliadoChefia">
                        <v-btn class="white--text" color="warning" rounded x-small>
                            Pendente
                        </v-btn>
                    </v-row>
                    <v-row v-else>
                        <v-btn class="white--text" color="success" rounded x-small>
                            Concluída
                        </v-btn>
                    </v-row>
                </template>

                <template v-slot:[`item.acao`]="{ item }">
                    <v-row justify="center">
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn id="btnExcluir" icon small :color="'primary'" v-bind="attrs" v-on="on" @click="showDialogDelete(item)">
                                    <v-icon> mdi-magnify </v-icon>
                                </v-btn>
                            </template>
                            <span>Visualizar avaliações</span>
                        </v-tooltip>
                    </v-row>
                </template> 
            </v-data-table>
        </v-col>
    </v-row>
    <v-row v-if="pagination.total">
        <v-col cols="10" class="text-right"></v-col>
        <v-col cols="2" class="ma-0 mb-6 pa-2 px-4 d-flex flex-row justify-end align-end text-right elevation-0">
            <v-btn color="success" @click="exportarXls()">
                <v-icon>mdi-download</v-icon> Exportar para excel
            </v-btn>
        </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
            <h4 class="primary--text text-center py-6">
                Tem certeza que deseja excluir?
                </h4>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="dialogDelete = false">Fechar</v-btn>
            <v-btn color="red darken-1" text @click="excluir()">Excluir</v-btn>
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSuccess" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAlert" width="570">
        <v-card>
            <v-card-title class="text-h5">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    </div>
</template>

<script>
import { getAllCiclos } from '../api/ciclo';
import { getGratificacoes } from '../api/gratificacao';
import { getGerenciarAvaliacao, getTotalGerenciarAvaliacao, getAvaliacoesByServidorByCiclo, extracaoGerenciarAvaliacao } from '../api/avaliacao';
import { getOrgaos } from '../api/orgao';
import { getServidorWithOrgao } from '../api/servidor';

import ExcluirAvaliacoesDialog from '../components/ExcluirAvaliacoesDialog.vue';
import CadastrarNotaAvaliacaoDialog from '../components/CadastrarNotaAvaliacaoDialog.vue';

export default {
    name: 'GerenciarAvaliacaoListar',
    components: {
        ExcluirAvaliacoesDialog,
        CadastrarNotaAvaliacaoDialog       
    },
    data() {
        return {
            ciclos: [],
            gratificacoes: [],  
            filtros: { },
            headers: [
                { text: 'CPF', value: 'nr_cpf_servidor', sortable: false},
                { text: 'Nome', value: 'nm_servidor', sortable: false},
                { text: 'Gratificação', value: 'sg_gratificacao' , sortable: false},
                { text: 'Avaliação da chefia', value: 'booAvaliadoChefia' , sortable: false},
                { text: 'Órgão', value: 'nm_orgao' , sortable: false},
                { text: 'Ações', value: 'acao', align: 'center', sortable: false}
            ],
            avaliacoesCadastradas: [],
            pagination: {
                current: 1,
                total: 0,
                page: 1,
                itemsPerPage: 10
            },
            dialogDelete:false,
            dialogSuccess: false,
            dialogAlert: false,
            dialogMsg:'',
            isGestorOrgao: false,
            orgaos: [],
        };
    },
    mounted() {
         if (
            this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
            && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
        ) {
            this.isGestorOrgao = true;

            getServidorWithOrgao(this.$store.getters['auth/usuarioAutenticado']).then(response => {
                var org = response.data.data.orgao;                    
                this.orgaos.unshift({text: org.sg_sigla + ' - ' + org.nm_orgao, value: org.cd_orgao, cd_orgao: org.cd_orgao});
                this.filtros.orgao = this.orgaos[0];
            });
        } else {
            getOrgaos().then(response => {
                this.orgaos = response.data.data; 
                this.orgaos.map(org =>{
                    org.text = org.sg_sigla + ' - ' + org.nm_orgao
                    org.value = org.cd_orgao
                })
                this.orgaos.unshift({text: 'Qualquer', value: -1, cd_orgao: -1});
            });
        }

        getAllCiclos().then(response => { 
            this.ciclos = response.data.data; 
        });

        getGratificacoes().then(response => {
            this.gratificacoes = response.data.data;
            this.gratificacoes = _.orderBy(this.gratificacoes, 'sg_gratificacao');
            this.gratificacoes.unshift({id_gratificacao: -2, sg_gratificacao: 'Com gratificação'});
            this.gratificacoes.unshift({id_gratificacao: -1, sg_gratificacao: 'Todas'});
        });
    },
    methods: {
        getFiltros() {
            var filtros = [];
            var obj = {};

            if ( this.filtros.id_ciclo ) {
                obj['id_ciclo'] = this.filtros.id_ciclo;
            }

            if( this.filtros.nm_servidor ) {
                obj['nm_servidor'] = this.filtros.nm_servidor.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
            }

            if ( this.filtros.nr_cpf_servidor ) {
                obj['nr_cpf_servidor'] = this.filtros.nr_cpf_servidor.replace(/[-.]/g,'');
            }

            if ( this.filtros.id_gratificacao ) {
                obj['id_gratificacao'] = this.filtros.id_gratificacao;
            }

            if(this.filtros.orgao && this.filtros.orgao.cd_orgao !== -1) {
                obj['cd_orgao'] = this.filtros.orgao.cd_orgao;
            }

            filtros.push(obj);
            return filtros;
        },

        filtrarGerenciarAvaliacao() {
            if ( this.$refs['form_ciclo'].validate() ) {
                var filtros = [];
                filtros = this.getFiltros();

                getGerenciarAvaliacao(
                    this.pagination.page, 
                    this.pagination.itemsPerPage, 
                    filtros
                ).then(response => { 
                    this.avaliacoesCadastradas = response.data.data;
                    
                });

                getTotalGerenciarAvaliacao(filtros)
                    .then(response => {
                        this.pagination.total = response.data.data;
                    });
            }
        },

        showDialogDelete(item) {
            var avaliacoesServidor;
            getAvaliacoesByServidorByCiclo(item.id_servidor,item.id_ciclo).then(response => {
                avaliacoesServidor = response.data.data;
                this.$refs.excluirAvaliacoesDialog.showDialog(avaliacoesServidor);
            }).catch(erro => {
                console.log(erro);
                this.dialogMsg = "Erro ao tentar excluir";
                this.dialogAlert = true;
            });
        },

        modalCadastrarNotaAvaliacao() {
            this.$refs.cadastrarNotaAvaliacaoDialog.showDialog();
        },

        exportarXls() {
            var filtros = [];
            filtros = this.getFiltros();

            extracaoGerenciarAvaliacao(filtros).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'Gerenciar avaliação.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        }
    }
}
</script>