import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

Vue.use(VueRouter);

import Home from './views/Home.vue';
import TelaInicial from './views/TelaInicial.vue';
import CiclosListar from './views/CiclosListar.vue';
import CiclosDetalhar from './views/CiclosDetalhar.vue';
import CiclosAdicionar from './views/CiclosAdicionar.vue';
import PlanosTrabalhoListar from './views/PlanosTrabalhoListar.vue';
import PlanosTrabalhoDetalhar from './views/PlanosTrabalhoDetalhar.vue';
import AvaliacoesListar from './views/AvaliacoesListar.vue';
import AvaliacoesDetalhar from './views/AvaliacoesDetalhar.vue';
import AvaliacoesResultado from './views/AvaliacoesResultado.vue';
import ReconsideracoesListar from './views/ReconsideracoesListar.vue';
import ReconsideracoesDetalhar from './views/ReconsideracoesDetalhar.vue';
import RelatoriosListar from './views/RelatoriosListar.vue';
import ServidoresListar from './views/ServidoresListar.vue';
import ServidoresDetalhar from './views/ServidoresDetalhar.vue';
import UnidadesListar from './views/UnidadesListar.vue';
import UnidadesDetalhar from './views/UnidadesDetalhar.vue';
import RDIAdicionar from './views/RDIAdicionar.vue';
import RDIListar from './views/RDIListar.vue';
import RDIDetalhar from './views/RDIDetalhar.vue';
import RDIEditar from './views/RDIEditar.vue';
import FatoresListar from './views/FatoresListar.vue';
import AdministradoresListar from './views/AdministradoresListar.vue';
import Erro403 from './views/Erro403.vue';
import Erro404 from './views/Erro404.vue';
import Valida from './components/auth/Valida.vue';
import RelatorioPlanoTrabalhoListar from './views/RelatorioPlanoTrabalhoListar.vue';
import RelatorioAvaliacaoListar from './views/RelatorioAvaliacaoListar.vue';
import RelatorioEquipeTrabalhoListar from './views/RelatorioEquipeTrabalhoListar.vue';
import EquipeTrabalhoModal from './views/EquipeTrabalhoModal.vue';
import AvaliacoesListaResultado from './views/AvaliacoesListaResultado.vue';
import AvaliadoresAvaliadosExternoCadastrar from './views/AvaliadoresAvaliadosExternoCadastrar.vue';
import AvaliadoresAvaliadosExternoListar from './views/AvaliadoresAvaliadosExternoListar.vue';
import AvaliadoresAvaliadosExternoEditar from './views/AvaliadoresAvaliadosExternoEditar.vue';
import NotificacaoUnidadeGestaoListar from './views/NotificacaoUnidadeGestaoListar.vue';
import NotificacaoUnidadeGestaoCadastrar from './views/NotificacaoUnidadeGestaoCadastrar.vue';
import AfastamentoServidorListar from './views/AfastamentoServidorListar.vue';
import Logout from './views/Logout.vue';
import AvaliacoesCadastradasListar from './views/AvaliacoesCadastradasListar.vue';
import CedidosRequisitadosDescentralizadosListar from './views/CedidosRequisitadosDescentralizadosListar.vue';
import GerenciarAvaliacaoListar from './views/GerenciarAvaliacaoListar.vue';
import RecursoListar from './views/RecursoListar.vue';
import PortariaEmitir from './views/PortariaEmitir.vue';
import AvaliacoesGroupResultado from './views/AvaliacoesGroupResultado.vue';
import InformativoTelaInicialCadastrar from './views/InformativoTelaInicialCadastrar.vue';
import LegislacaoAplicadaCadastrar from './views/LegislacaoAplicadaCadastrar.vue';
import LegislacaoAplicadaListar from './views/LegislacaoAplicadaListar.vue';
import OrgaosCadastrar from './views/OrgaosCadastrar.vue';
import GratificacoesListar from './views/GratificacoesListar.vue';
import AgendarEmailCadastrar from './views/AgendarEmailCadastrar.vue';


let routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: false,
            menu: {
                show: false,
                titulo: 'Página inicial'
            },
            breadcrumb: {
                show: false,
            }
        },
        children: [
            {
                path: 'logout',
                name: 'logout',
                submenu: false,
                component: Logout,
                meta: {
                    requiresAuth: false,
                    breadcrumb: {
                        show: false,
                        titulo: 'logout'
                    }
                },
            },
        ]
    },
    {
        path: '/tela-inicial',
        name: 'tela-inicial',
        component: TelaInicial,
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'servidor', 'chefia', 'gestorSetorial', 'pontoFocal','gestorOrgao'],
            menu: {
                show: true,
                titulo: 'Página inicial'
            },
            breadcrumb: {
                show: false,
            }
        },
    },
    {
        path: '/ciclos',
        name: 'ciclos',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador'],
            menu: {
                show: true,
                titulo: 'Ciclos'
            },
            breadcrumb: {
                show: true,
                titulo: 'Ciclos'
            }
        },
        children: [
            {
                path: '/ciclos',
                name: 'ciclos-listar',
                component: CiclosListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Ciclos'
                    }
                },
            },
            {
                path: 'adicionar',
                name: 'ciclos-adicionar',
                component: CiclosAdicionar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Adicionar'
                    }
                },
            },
            {
                path: ':id',
                name: 'ciclos-detalhar',
                component: CiclosDetalhar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Detalhes'
                    }
                }
            },      
        ]
    },
    {
        path: '/planos-trabalho',
        name: 'planos-trabalho',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'servidor', 'chefia', 'gestorSetorial', 'pontoFocal','gestorOrgao'],
            menu: {
                show: true,
                titulo: 'Planos de Trabalho'
            },
            breadcrumb: {
                show: false,
                titulo: 'Planos de Trabalho'
            }
        },
        children: [
            {
                path: '',
                name: 'planos-trabalho-listar',
                component: PlanosTrabalhoListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'servidor', 'chefia', 'gestorSetorial', 'pontoFocal', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Planos de Trabalho'
                    }
                },
            },
            {
                path: ':id',
                name: 'planos-trabalho-detalhar',
                component: PlanosTrabalhoDetalhar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'servidor', 'chefia', 'gestorSetorial', 'pontoFocal', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Detalhes'
                    }
                },
            },
        ]
    },
    {
        path: '/avaliacoes',
        name: 'avaliacoes',
        component: {
            render (c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador','servidor','chefia','gestorSetorial','pontoFocal','servidorExterno', 'gestorOrgao'],
            menu: {
                show: true, 
                titulo: 'Avaliações'
            },
            breadcrumb: {
                show: false,
                titulo: ''
            }
        },
        children: [
            {
                path: ':id',
                name: 'avaliacoes-detalhar',
                component: AvaliacoesDetalhar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador','servidor','chefia','gestorSetorial','pontoFocal','servidorExterno', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Detalhar'
                    }
                },
            },
            {
                path: '/avaliacoes',
                name: 'Realizar Avaliação',
                submenu: true,
                component: AvaliacoesListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador','servidor','chefia','gestorSetorial','pontoFocal','servidorExterno', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Realizar Avaliação'
                    }
                },
            },
            {
                path: '/listaResultado',
                name: 'Resultados das Avaliações',
                submenu: true,
                component: AvaliacoesListaResultado,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador','servidor','chefia','gestorSetorial','pontoFocal', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Resultados das Avaliações'
                    }
                },
            },
            {
                path: '/listaResultado/:idCiclo',
                name: 'avaliacoes-resultado',
                component: AvaliacoesGroupResultado,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador','servidor','chefia','gestorSetorial','pontoFocal', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Resultado'
                    }
                },
            },
            {
                path: '/AvaliacoesCadastradas',
                name: 'Avaliações Cadastradas',
                submenu: true,
                component: AvaliacoesCadastradasListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Avaliações Cadastradas'
                    }
                },
            },
            {
                path: '/GerenciarAvaliacao',
                name: 'Gerenciar Avaliação',
                submenu: true,
                component: GerenciarAvaliacaoListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Gerenciar Avaliação'
                    }
                },
            },
        ]
    },
    {
        path: '/reconsideracoes',
        name: 'reconsideracoes',
        component: {
            render (c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador','chefia','servidorExterno','gestorSetorial','pontoFocal','servidor', 'gestorOrgao'],
            menu: {
                show: true, 
                titulo: 'Reconsiderações'
            },
            breadcrumb: {
                show: false,
                titulo: 'Reconsiderações'
            }
        },
        children: [
            {
                path: '',
                name: 'reconsideracoes-listar',
                component: ReconsideracoesListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador','chefia','servidorExterno','gestorSetorial','pontoFocal','servidor', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Reconsiderações'
                    }
                },
            },
            {
                path: ':id',
                name: 'reconsideracoes-detalhar',
                component: ReconsideracoesDetalhar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador','chefia','servidorExterno','gestorSetorial','pontoFocal','servidor', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Detalhar'
                    }
                },
            },
        ]
    },
    {
        path: '/extracao',
        name: 'extracao',
        component: RelatoriosListar,
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'gestorOrgao'],
            menu: {
                show: true,
                titulo: 'Extração'
            },
            breadcrumb: {
                show: true,
                titulo: 'Extração'
            }
        }
    },
    {
        path: '/servidores',
        name: 'servidores',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'chefia', 'gestorOrgao'],
            menu: {
                show: true,
                titulo: 'Servidores'
            },
            breadcrumb: {
                show: false,
                titulo: 'Servidores'
            }
        },
        children: [
            {
                path: '',
                name: 'servidores-listar',
                component: ServidoresListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'chefia', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Servidores'
                    }
                },
            },
            {
                path: ':id',
                name: 'servidores-detalhar',
                component: ServidoresDetalhar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'chefia', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Detalhes'
                    }
                },
            }
        ]
    },
    {
        path: '/unidades',
        name: 'unidades',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'chefia', 'gestorOrgao'],
            menu: {
                show: true,
                titulo: 'Unidades'
            },
            breadcrumb: {
                show: false,
                titulo: 'Unidades'
            }
        },
        children: [
            {
                path: '',
                name: 'unidades-listar',
                component: UnidadesListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'chefia', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Unidades'
                    }
                },
            },
            {
                path: ':id',
                name: 'unidades-detalhar',
                component: UnidadesDetalhar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'chefia', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Detalhes'
                    }
                },
            },
        ]
    },
    {
        path: '/administradores',
        name: 'administradores',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador'],
            menu: {
                show: true,
                titulo: 'Administradores',
            },
            breadcrumb: {
                show: false,
                titulo: 'Administradores'
            }
        },
        children: [
            {
                path: '',
                name: 'administradores-listar',
                component: AdministradoresListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Administradores'
                    }
                },
            },
        ]
    },
    {
        path: '/fatores',
        name: 'fatores',
        component: FatoresListar,
        meta: {
            requiresAuth: true,
            roles: ['administrador'],
            menu: {
                show: true,
                titulo: 'Fatores'
            },
            breadcrumb: {
                show: true,
                titulo: 'Fatores'
            }
        },
    },
    {
        path: '/rdi',
        name: 'rdi',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador'],
            menu: {
                show: true,
                titulo: 'RDI'
            },
            breadcrumb: {
                show: true,
                titulo: 'RDI'
            }
        },
        children: [
            {
                path: '',
                name: 'rdi-listar',
                component: RDIListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'RDI'
                    }
                },
            },
            {
                path: 'adicionar',
                name: 'rdi-adicionar',
                component: RDIAdicionar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Adicionar'
                    }
                },
            },
            {
                path: ':id',
                name: 'rdi-detalhar',
                component: RDIDetalhar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Detalhes'
                    }
                },
            },
            {
                path: 'editar/:id',
                name: 'rdi-editar',
                component: RDIEditar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Detalhes'
                    }
                },
            }
        ]
    },
    {
        path: '',
        name: '',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'gestorSetorial', 'pontoFocal','chefia', 'gestorOrgao'],
            menu: {
                show: true,
                titulo: 'Relatórios'
            },
            breadcrumb: {
                show: true,
                titulo: ''
            },
        },
        children: [
            {
                path: '/avaliacoes-desempenho',
                name: 'Relatório de Avaliações de desempenho',
                submenu: true,
                component: RelatorioAvaliacaoListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'chefia', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Avaliações de desempenho'
                    }
                },
            },
            {
                path: '/equipe-trabalho',
                name: 'Relatório de equipe de trabalho',
                submenu: true,
                component: RelatorioEquipeTrabalhoListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'chefia', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Equipes de trabalho'
                    }
                },
            },
            {
                path: '/avaliacoes-desempenho/detalheAvaliacaoServidor/:idCiclo/:idConsulta/:sglTipo',
                name: 'detalheAvaliacaoServidor',
                component: AvaliacoesGroupResultado,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'chefia', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Resultado da Avaliação de Desempenho'
                    }
                },
            },
            {
                path: '/relatorio-plano-trabalho',
                name: 'Relatório de plano de trabalho',
                submenu: true,
                component: RelatorioPlanoTrabalhoListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Plano de trabalho'
                    }
                },
            },
            {
                path: '/relatorio-plano-trabalho/detalhePlanoTrabalho/:id_plano_trabalho',
                name: 'detalhePlanoTrabalho',
                component: PlanosTrabalhoDetalhar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Detalhe'
                    }
                },
            },
            {
                path: '/equipe-trabalho/EquipeTrabalhoModal/:id/:id_servidor',
                name: 'EquipeTrabalhoModal',
                component: EquipeTrabalhoModal,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Dados da equipe de trabalho'
                    }
                },
            },
           
        ]
    },
    {
        path: '',
        name: '',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'gestorOrgao'],
            menu: {
                show: true,
                titulo: 'Afastamento'
            },
            breadcrumb: {
                show: true,
                titulo: ''
            },
        },
        children: [
            {
                path: '/servidores-afastados',
                name: 'Servidores afastados',
                submenu: true,
                component: AfastamentoServidorListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Servidores afastados'
                    }
                },
            },
            {
                path: '/avaliadores-avaliados-externo',
                name: 'Avaliadores/Avaliados externos',
                submenu: true,
                component: AvaliadoresAvaliadosExternoListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Avaliadores Avaliados externos'
                    }
                },
            },
            {
                path: '/avaliadores-avaliados-externo/formulario-cedidos-requisitados',
                name: 'formulario-cedidos-requisitados',
                component: AvaliadoresAvaliadosExternoCadastrar,
                meta: {
                    requiresAuth: false,
                    roles: ['administrador', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de avaliador e avaliado cedido/requisitado'
                    }
                },
            },
            {
                path: '/avaliadores-avaliados-externo/editar/:id_equipe_trabalho_externo',
                name: 'editarEquipeTrabalhoExterno',
                component: AvaliadoresAvaliadosExternoEditar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Editar avaliador/avaliado'
                    }
                },
            },
            {
                path: '/cedidos-requisitados-descentralizados',
                name: 'Ativos em outro órgão',
                submenu: true,
                component: CedidosRequisitadosDescentralizadosListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Ativos em outro órgão'
                    }
                },
            },
        ]
    },
    {
        path: '',
        name: '',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'gestorOrgao'],
            menu: {
                show: true,
                titulo: 'Notificação'
            },
            breadcrumb: {
                show: true,
                titulo: ''
            },
        },
        children: [
            {
                path: '/notificacao-unidade-gestao',
                name: 'Unidade de gestão de pessoas',
                submenu: true,
                component: NotificacaoUnidadeGestaoListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Unidade de gestão de pessoas'
                    }
                },
            },
            {
                path: '/notificacao-unidade-gestao/cadastrar',
                name: 'cadastrarNotificacaoUnidadeGestao',
                component: NotificacaoUnidadeGestaoCadastrar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastrar notificação para unidade de gestão'
                    }
                },
            },
            {
                path: '/informativo-tela-inicial',
                name: 'Cadastro de informativo',
                submenu: true,
                component: InformativoTelaInicialCadastrar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'gestorOrgao'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de informativo para tela inicial'
                    }
                },
            },
            {
                path: '/AgendarEmail',
                name: 'Agendar E-mail para servidores',
                submenu: true,
                component: AgendarEmailCadastrar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Agendar envio de e-mail para servidores'
                    }
                },
            },
        ]
    },
    {
        path: '/recurso-listar',
        name: 'Recursos',
        component: RecursoListar,
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'gestorSetorial', 'pontoFocal', 'gestorOrgao'],
            menu: {
                show: true,
                titulo: 'Recursos'
            },
            breadcrumb: {
                show: true,
                titulo: 'Recurso dos servidores avaliados'
            },
        },
    },
    {
        path: '/legislacao-aplicada',
        name: 'Legislação Aplicada',
        component: LegislacaoAplicadaCadastrar,
        meta: {
            requiresAuth: true,
            roles: ['administrador'],
            menu: {
                show: true,
                titulo: 'Legislação Aplicada'
            },
            breadcrumb: {
                show: true,
                titulo: 'Legislação Aplicada'
            },
        },
    },
    {
        path: '/informacoes-legislacao-aplicada',
        name: 'Informações de Legislação Aplicada',
        component: LegislacaoAplicadaListar,
        meta: {
            requiresAuth: false,
            menu: {
                show: false,
                titulo: 'Informações de Legislação Aplicada'
            },
            breadcrumb: {
                show: true,
                titulo: 'Legislação Aplicada'
            },
        },
    },
    {
        path: '/portaria',
        name: 'portaria',
        component: PortariaEmitir,
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'gestorOrgao'],
            menu: {
                show: true,
                titulo: 'Portaria'
            },
            breadcrumb: {
                show: true,
                titulo: 'Portaria'
            }
        },
    },
    {
        path: '/gerenciar-orgaos',
        name: 'OrgaosCadastrar',
        component: OrgaosCadastrar,
        meta: {
            requiresAuth: true,
            roles: ['administrador'],
            menu: {
                show: true,
                titulo: 'Gerenciar órgãos'
            },
            breadcrumb: {
                show: true,
                titulo: 'Gerenciar órgãos'
            }
        },
    },
    {
        path: '/gratificacoes',
        name: 'GratificacoesListar',
        component: GratificacoesListar,
        meta: {
            requiresAuth: true,
            roles: ['administrador'],
            menu: {
                show: true,
                titulo: 'Gratificações'
            },
            breadcrumb: {
                show: true,
                titulo: 'Gratificações'
            }
        },
    },
    {
        path: '/valida',
        name: 'valida',
        component: Valida,
        meta: {
            requiresAuth: false,
            menu: {
                show: false,
            },
            breadcrumb: {
                show: false,
            }
        }
    },
    {
        path: '/erro-403',
        name: 'erro403',
        component: Erro403,
        meta: {
            requiresAuth: false,
            menu: {
                show: false,
            },
            breadcrumb: {
                show: false,
            }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'erro404',
        component: Erro404,
        meta: {
            requiresAuth: false,
            menu: {
                show: false
            },
            breadcrumb: {
                show: false,
            }
        }
    },
];

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
});

router.beforeEach((to, from, next) => {
    store.dispatch('alertas/hide');
    if (to.meta.requiresAuth) {
        if ( store.getters['auth/isLoggedIn'] || store.getters['auth/isExternoLoggedIn'] ) {
            if ( store.getters['auth/usuarioAutenticadoRoles'].some(r => to.meta.roles.indexOf(r) >= 0) ) {
                next();
            }
            else {
                router.push({ name: 'tela-inicial' },
                    () => store.dispatch('alertas/show', {
                        tipo: 'Erro',
                        titulo: 'Acesso negado',
                        mensagem: 'Você não possui permissão necessária para acessar a página desejada.'
                    })
                );
            }
        }
        else {
            router.push({ name: 'home' },
                () => store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Página requer autenticação',
                    mensagem: 'É necessário realizar login na aplicação para acessar a página desejada.'
                })
            );
        }
    }
    next();
});
