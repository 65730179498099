<template>
    <div class="pt-8">
        <p>
        	O AvaliaMGI é o sistema responsável por implementar o processo de avaliação de desempenho individual e institucional, para fins, entre outros,  de pagamento de gratificações de desempenho devida aos servidores ocupantes de cargos efetivos do quadro de pessoal do Ministério da Gestão e Inovação em Serviços Públicos.
		</p>
        <p>
            Para acessá-lo, é necessário ter uma conta 
            <a href="https://contas.acesso.gov.br/" target="_blank">
                <strong class="logoGovBr">gov.br</strong>
            </a> 
             e ser servidor do Ministério da Gestão e Inovação em Serviços Públicos.  
        </p>
        <br>
        <v-btn color="warning" class="primary--text" href="/informacoes-legislacao-aplicada">
            <v-icon left>mdi mdi-scale-balance</v-icon>Legislacao Aplicada
        </v-btn>  
    </div>
</template>

<script>
    export default {
        name: 'home-app',
        created() {
		},
    }
</script>
<style>
.logoGovBr {
   font-size: 30px;
   background: linear-gradient(to right,
      #155BCB 20%,
      #FFCD07 20%, #FFCD07 42%,
      #168821 42%, #168821 58%,
      #155BCB 58%, #155BCB 87%,
      #FFCD07 87%
    );
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
    font-weight: 1000;
    font-family: arial;
    letter-spacing: -1px;
}
</style>