<template>
    <div>
        <ativoEmOutroOrgaoDialog ref="ativoEmOutroOrgaoDialog" />
        <h1>Ativos em outro órgão</h1>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-2">
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="cpf" 
                                v-model="filtros.nr_cpf" 
                                ref="cpf" 
                                dense 
                                hide-details="auto" 
                                label="CPF" 
                                v-mask="'###.###.###-##'" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                             <v-text-field 
                                id="siape" 
                                ref="siape" 
                                v-model="filtros.nr_siape" 
                                dense 
                                hide-details="auto" 
                                label="SIAPE" 
                                v-mask="'#######'" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                           <v-text-field 
                                id="nome" 
                                v-model="filtros.nm_servidor" 
                                ref="nome" 
                                dense 
                                hide-details="auto" 
                                label="Nome" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="gratificacao"
                                :items="gratificacoes"
                                v-model="filtros.id_gratificacao"
                                item-value="id_gratificacao"
                                item-text="sg_gratificacao"
                                label="Gratificação"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-combobox
                                id="funcao"
                                ref="funcoes"
                                :items="funcoes"
                                v-model="filtros.funcao"
                                outlined
                                dense
                                label="Função"
                                hide-details="auto"
                                placeholder="Digite a função"
                            ></v-combobox>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="codigosAfastamentos"
                                :items="codigosAfastamentos"
                                v-model="filtros.id_situacao_afastamento"
                                item-value="id_situacao_afastamento"
                                item-text="co_afastamento_siape"
                                label="Código do Afastamento"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-combobox
                                id="orgao"
                                ref="orgao"
                                :items="orgaos"
                                v-model="filtros.nm_orgao_requisitante"
                                hide-details
                                outlined 
                                dense
                                placeholder="Órgão requisitante"
                            ></v-combobox>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="situacoes"
                                :items="situacoes"
                                v-model="filtros.situacoes"
                                label="Avaliação Bloqueada/Desbloqueada"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select> 
                        </v-col>
                    </v-row>

                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-menu
                                v-model="dataInicio"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-mask="'####-##-##'"
                                        type="date"
                                        label="Data de início (a partir de)"
                                        readonly
                                        v-model="filtros.dataInicio"
                                        v-on="on"
                                        clearable
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="pt-br"
                                    v-model="filtros.dataInicio"
                                    no-title
                                    @input="dataInicio = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-menu
                                v-model="dataFim"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-mask="'####-##-##'"
                                        type="date"
                                        label="Data de término (até)"
                                        readonly
                                        v-model="filtros.dataFim"
                                        v-on="on"
                                        clearable
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="pt-br"
                                    v-model="filtros.dataFim"
                                    no-title
                                    @input="dataFim = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="10" class="text-right"></v-col>
                        <v-col 
                            cols="2" 
                            class="pr-8 pb-8 d-flex flex-row justify-end align-end text-right">
                            <v-btn 
                                id="btnFiltrar" 
                                color="primary" 
                                @click="gerarRelatorio()"
                            >
                                <v-icon>mdi-magnify</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <br>

        <v-data-table
            :headers="headers"
            :items="itemsTable"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="{'items-per-page-options':[10]}"
            :options.sync="pagination" 
            :server-items-length="pagination.total"
            class="elevation-1"
            :hide-default-footer = true
        >
            <template v-slot:footer v-if="pagination.total">
                <v-divider></v-divider><br>
                <v-pagination
                    v-model="pagination.current"
                    :length="last_page"
                    :total-visible="5"
                    circle
                    small
                    @input="gerarRelatorio"
                ></v-pagination>
                <h6 class="text-center grey--text">Total: {{pagination.total}}</h6>
            </template>

            <template v-slot:[`item.dtInicio`]="{ item }">
                <v-row  v-if="item.dtInicio">
                        {{ formataData(item.dtInicio) }}
                </v-row>
            </template>
            <template v-slot:[`item.dtFim`]="{ item }">
                <v-row  v-if="item.dtFim">
                        {{ formataData(item.dtFim) }}
                </v-row>
            </template>

            <template v-slot:[`item.acao`]="{ item }">
                <v-row justify="center">
                    <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                fab 
                                dark 
                                x-small 
                                color="blue-grey" 
                                v-bind="attrs" 
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-tooltip top>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn 
                                            id="btnVisualizar" 
                                            icon 
                                            small 
                                            :color="'primary'" 
                                            v-bind="attrs" 
                                            v-on="on"
                                            @click="visualizar(item.id_servidor)"
                                        >
                                            <v-icon> mdi-eye </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Visualizar</span>
                                </v-tooltip>
                                <v-tooltip top v-if="!item.st_bloqueio_externo">
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn 
                                            class="mr-2 mb-1 mt-1" 
                                            id="btnliberar" 
                                            icon 
                                            small 
                                            :color="'primary'" 
                                            v-bind="attrs" 
                                            v-on="on" 
                                            @click="()=>showDialogBloquear(
                                                item.id_servidor
                                            )"
                                        >
                                            <v-icon>mdi mdi-lock</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Bloquear Avaliação Externa</span>
                                </v-tooltip>
                                <v-tooltip top v-else>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn 
                                            class="mr-2 mb-1 mt-1" 
                                            id="btnDesbloquear" 
                                            icon 
                                            small 
                                            :color="'primary'" 
                                            v-bind="attrs" 
                                            v-on="on"
                                            @click="showDialogDesbloquear(item.id_servidor)"
                                        >
                                            <v-icon>mdi-lock-open</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Desbloquear Avaliação Externa</span>
                                </v-tooltip>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-row>
            </template>
        </v-data-table>
        
        <div class="mt-4" v-if="pagination.total">
            <v-btn 
                color="success" 
                class="float-right" 
                @click="exportarExcel()"
            >
                <v-icon>mdi-download</v-icon> Exportar para excel
            </v-btn>
            <v-btn 
                class="float-right mr-2" 
                color="error"
                @click="showBloquearDesbloquearEmLote()"
            >
                Bloquear/desbloquear avaliação em lote
            </v-btn>
        </div>

        
        <v-dialog v-model="dialogDesbloquear" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-card-title>
                        <v-icon large left> mdi-lock-open </v-icon>
                        <span class="text-h6 font-weight-light">
                            Desbloquear avaliação externa
                        </span>
                    </v-card-title>
                </v-card>
                <br>
                <v-container>
                    <h3 class="text-center font-weight-light primary--text">
                        Desbloquear avaliação externa
                    </h3>
                    
                    <p class="text-center" >
                        Por favor, insira uma justificativa explicando o motivo desta ação.
                    </p>
                    <v-row dense class="ma-0">
                        <v-col cols="12" class="px-3">
                            <v-textarea
                            v-model="justificativa"
                            id="justificativaDesbloquear"
                            outlined
                            rows="2"
                            required
                            :rules="[() => !!justificativa || 'Campo obrigatório.']"
                            name="justificativaDesbloquear"
                            label="Justificativa"
                            ></v-textarea>
                        </v-col>      
                    </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="closeDialogDesbloquear()">Fechar</v-btn>
                    <v-btn  color="primary" text @click="desbloquear()">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogBloquearAvaliacaoExterna" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-card-title>
                        <v-icon large left> mdi-lock </v-icon>
                        <span class="text-h6 font-weight-light">
                            Bloquear avaliação externa
                        </span>
                    </v-card-title>
                </v-card>
                <br>
                <v-container>
                    <h3 class="text-center font-weight-light primary--text">
                        Bloquear avaliação externa
                    </h3>
                    
                    <p class="text-center" >
                        Por favor, insira uma justificativa explicando o motivo desta ação.
                    </p>
                    <v-row dense class="ma-0">
                        <v-col cols="12" class="px-3">
                            <v-textarea
                            v-model="justificativa"
                            id="justificativaBloquearAvaliacaoExterna"
                            outlined
                            rows="2"
                            required
                            :rules="[() => !!justificativa || 'Campo obrigatório.']"
                            name="justificativaBloquearAvaliacaoExterna"
                            label="Justificativa"
                            ></v-textarea>
                        </v-col>      
                    </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="closeDialogBloquearAvaliacaoExterna()">Fechar</v-btn>
                    <v-btn  color="primary" text @click="bloquearAvaliacaoExterna()">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialogAlertDesbloquear" width="580">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlertDesbloquear = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAlert" width="580">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialogAlertBloquearDesbloquearEmLote" width="580">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                Necessário preencher pelo menos um<br> dos campos.
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlertBloquearDesbloquearEmLote = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess =false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialogBloquearDesbloquearEmLote" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-card-title>
                        <span class="text-h6 font-weight-light">
                            Bloquear/desbloquear avaliação externa em lote
                        </span>
                    </v-card-title>
                </v-card>
                <br>
                <v-container>
                    <h3 class="text-center font-weight-light primary--text">
                        Bloquear/desbloquear avaliação externa em lote
                    </h3>
                    <v-radio-group v-model="radioGroup" class="mt-3">
                        <v-row>
                            <v-col cols="3"></v-col>
                            <v-col cols="3" class="pr-0">
                                <v-radio
                                    label="Bloquear"
                                    color="error"
                                    value="2"
                                    required
                                    :rules="[() => !!radioGroup|| 'Campo obrigatório.']"
                                ></v-radio>
                            </v-col>
                            <v-col cols="3">
                                <v-radio
                                    label="Desbloquear"
                                    color="primary"
                                    value="1"
                                    :rules="[() => !!radioGroup || 'Campo obrigatório.']"
                                ></v-radio>
                            </v-col>
                            <v-col cols="3"></v-col>
                        </v-row>
                    </v-radio-group>
                    <p class="text-center" >
                        Por favor, insira uma justificativa explicando o motivo desta ação.
                    </p>
                    <v-row dense class="ma-0">
                        <v-col cols="12" class="px-3">
                            <v-textarea
                            v-model="justificativa"
                            id="justificativaBloquearDesbloquear"
                            outlined
                            rows="2"
                            required
                            :rules="[() => !!justificativa || 'Campo obrigatório.']"
                            name="justificativaBloquearDesbloquear"
                            label="Justificativa"
                            ></v-textarea>
                        </v-col>      
                    </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="dialogBloquearDesbloquearEmLote = false">Fechar</v-btn>
                    <v-btn  color="primary" text @click="validaLote()">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="confirmarDialogBloquearDesbloquearEmLote" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <br>
                <v-container>                    
                    <p class="text-center" >
                        Tem certeza que deseja realizar essa ação em lote <br>
                        para esses servidores?
                    </p>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="confirmarDialogBloquearDesbloquearEmLote = false">Cancelar</v-btn>
                    <v-btn  color="primary" text @click="confirmarBloquearDesbloquearEmLote()">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>
import {getGratificacoes} from '../api/gratificacao';
import {getCodigosAfastamentosAtivosEmOutroOrgao} from '../api/situacao_afastamento';
import {exportarExcelAtivosEmOutroOrgao} from '../api/afastamento_servidor';
import {getRelatorioAtivosEmOutroOrgao, getTotalAtivosEmOutroOrgao} from '../api/afastamento_servidor';
import {getDetalheAtivoEmOutroOrgao} from '../api/afastamento_servidor';
import {getFuncoes} from '../api/funcao';
import {getOrgaosRequisitante} from '../api/servidor';
import {desbloquearAvaliacaoExterna} from '../api/gerenciar_afastamento_servidor';
import {bloquearDesbloquearEmLoteAtivosEmOutroOrgao} from '../api/gerenciar_afastamento_servidor';
import {bloquearAvaliacaoExterna} from '../api/avaliacao';
import moment from 'moment';

import { getCiclosVigentes } from '../api/ciclo';



import AtivoEmOutroOrgaoDialog from '../components/AtivoEmOutroOrgaoDialog.vue';

export default {
    name: 'CedidosRequisitadosDescentralizadosListar',

    components: {
        AtivoEmOutroOrgaoDialog,
    },

    data() 
    {
        return {
            filtros: {},
            rules: {},
            gratificacoes: [],
            codigosAfastamentos: [],
            orgaos: [],
            funcoes: [],
            situacoes: [
                { text: 'Selecione', value: null},
                { text: 'Bloqueado', value: 1},
                { text: 'Desbloqueado', value: 2}
            ],
            headers: [
                { text: 'CPF', value: 'nr_cpf', sortable: false },
                { text: 'Nome', value: 'nm_servidor', sortable: false },
                { text: 'Gratificação', value: 'sg_gratificacao', sortable: false },
                { text: 'Função', value: 'nm_funcao', sortable: false},
                { text: 'Órgão requisitante', value: 'nm_orgao_requisitante', sortable: false },
                { text: 'Código Afastamento', value: 'idAfastamento', sortable: false },
                { text: 'Nome Afastamento', value: 'dsSituacao', sortable: false },
                { text: 'Data início', value: 'dtInicio', sortable: false },
                { text: 'Data término', value: 'dtFim', sortable: false },
                { text: 'Ações', value: 'acao', align: 'center', sortable: false, width: '3px' }

            ],
            itemsTable: [],
            pagination: {
                current: 1,
                total: 0,
                page: 1,
                itemsPerPage: 10
            },
            tableResult: false,
            dialogDesbloquear: false,
            justificativa:'',
            dialogAlertDesbloquear: false,
            id_servidor: null,
            dialogSuccess: false,
            dialogMsg:'',
            radioGroup: null,
            dialogBloquearDesbloquearEmLote: false,
            confirmarDialogBloquearDesbloquearEmLote: false,
            dialogAlertBloquearDesbloquearEmLote: false,
            dialogMsgBloquearDesbloquearEmLote:'',
            orgao:'',
            dialogAlert: false,
            dialogBloquearAvaliacaoExterna: false,
            dataFim: false,
            dataInicio: false,
            last_page: 0
        };
    },
    mounted() {
        getGratificacoes()
            .then(response => {
                this.gratificacoes = response.data.data;
                this.gratificacoes = _.orderBy(this.gratificacoes, 'sg_gratificacao');
                this.gratificacoes.unshift({id_gratificacao: -1, sg_gratificacao: 'Todas'});
        });

        getCodigosAfastamentosAtivosEmOutroOrgao()
            .then(response => {
                this.codigosAfastamentos = response.data.data;
                this.codigosAfastamentos = _.orderBy(this.codigosAfastamentos, 'id_situacao_afastamento');
                this.codigosAfastamentos.unshift({id_situacao_afastamento: -1, co_afastamento_siape: 'Todos'});
        });

        getFuncoes()
            .then(response => { 
                this.funcoes = response.data.data; 
                this.funcoes.sort((a,b) => {   return a.nm_funcao.localeCompare(b.nm_funcao);});
                this.funcoes.map(func =>{
                    func.text = func.cd_funcao + ' - ' + func.nm_funcao
                    func.value = func.id_funcao
                    func.nm_funcao_com_sigla = func.cd_funcao + ' - ' + func.nm_funcao
                })
                this.funcoes.unshift({text: 'Qualquer', value: -1, id_funcao: -1})
                this.funcoes.unshift({text: 'Selecione', value: -2, id_funcao: -2})                  
        });

        getOrgaosRequisitante()
            .then(response => { 
                this.orgaos = response.data.data; 
                this.orgaos.map(orgao =>{
                    orgao.text = orgao.nm_orgao_requisitante
                    orgao.value = orgao.nm_orgao_requisitante
                })
                this.orgaos.unshift({text: 'Todos', value: -1, id_funcao: -1});
        });
    },
    methods: {
        getFiltros(){
            var filtros = [];
            var obj = {};

            if (this.filtros.nr_cpf) {
                obj['nr_cpf'] = this.filtros.nr_cpf.replace(/[-.]/g,'');
            }

            if ( this.filtros.nr_siape ) {
                obj['nr_siape'] = this.filtros.nr_siape;
            }

            if( this.filtros.nm_servidor ) {
                obj['nm_servidor'] = this.filtros.nm_servidor.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
            }

            if ( this.filtros.id_gratificacao ) {
                obj['id_gratificacao'] = this.filtros.id_gratificacao;
            }

            if( typeof this.filtros.funcao === 'object' ) {
                obj['funcao'] = this.filtros.funcao;
            } else {
                this.$refs.funcoes.lazyValue = undefined;
            }

            if ( this.filtros.id_situacao_afastamento ) {
                obj['id_situacao_afastamento'] = this.filtros.id_situacao_afastamento;
            }

            if ( typeof this.filtros.nm_orgao_requisitante === 'object' ) {
                obj['nm_orgao_requisitante'] = this.filtros.nm_orgao_requisitante;
            } else {
                this.$refs.orgao.lazyValue = undefined;
                this.filtros.nm_orgao_requisitante = undefined;
            }

            if ( this.filtros.dataInicio ) {
                obj['dt_inicio'] = this.filtros.dataInicio;
            }

            if ( this.filtros.dataFim ) {
                obj['dt_fim'] = this.filtros.dataFim;
            }

            if ( this.filtros.situacoes ) {
                obj['situacoes'] = this.filtros.situacoes;
            }

            filtros.push(obj);

            return filtros;
        },

        gerarRelatorio() {            
            var filtros = [];
            filtros = this.getFiltros();

            this.tableResult = true;

            getCiclosVigentes(this.$store.getters['auth/usuarioAutenticado']).then(response => {
                if (response.data.data.length > 0) {
                    getRelatorioAtivosEmOutroOrgao(
                        this.pagination.current, 
                        this.pagination.itemsPerPage, 
                        filtros
                    ).then(response =>{
                        this.itemsTable = response.data.data.data;
                        this.pagination.current = response.data.data.current_page;
                        this.pagination.total = response.data.data.total;
                        this.last_page = response.data.data.last_page;
                    })
                } else {
                    this.dialogMsg = 'O ciclo não está vigente.'
                    this.dialogAlert = true;
                }
            });
        },

        visualizar(id_servidor) {
            var afastamento;
            getDetalheAtivoEmOutroOrgao(id_servidor).then(response => {
                afastamento = response.data.data;
                this.$refs.ativoEmOutroOrgaoDialog.mostraAfastamentoDialog(afastamento);
            });
        },

        exportarExcel() {
            var filtros = [];
            filtros = this.getFiltros();

            exportarExcelAtivosEmOutroOrgao(filtros).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'AtivosEmOutroOrgao.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },

        bloquearAvaliacaoExterna() {
            if(this.justificativa == '') {
                this.dialogMsg = "Por favor, insira uma justificativa explicando o motivo desta ação.";
                this.dialogAlert = true;                
            } else {
                let data = new FormData();

                data.append('tx_observacao_avaliado', this.justificativa);
                data.append('st_bloqueado', false);
                data.append('st_bloqueio_externo', true);
                data.append('id_servidor', this.id_servidor);

                bloquearAvaliacaoExterna(data).then(response => {
                    if (!response.data.data) {
                        this.dialogMsg = response.data.message;
                        return this.dialogAlert = true;
                    } else {
                        this.gerarRelatorio();
                        this.dialogBloquearAvaliacaoExterna = false;
                        this.dialogMsg = response.data.message;
                        this.justificativa = '';
                        return this.dialogSuccess =true;
                    }
                })
                .catch(erro => {
                    console.log('Erro ao bloquear avaliação externa. ' + erro);
                });
            }
        },

        showDialogDesbloquear(id_servidor) {
            this.dialogDesbloquear = true;
            this.id_servidor = id_servidor;
        },

        showDialogBloquear(id_servidor) {
            this.dialogBloquearAvaliacaoExterna = true;
            this.id_servidor = id_servidor;
        },

        closeDialogDesbloquear() {
            this.dialogDesbloquear = false;
            this.justificativa = '';
        },

        closeDialogBloquearAvaliacaoExterna() {
            this.dialogBloquearAvaliacaoExterna = false;
            this.justificativa = '';
        },

        desbloquear() {  
            if(this.justificativa == '') {
                this.dialogMsg ="Por favor, insira uma justificativa explicando o motivo desta ação.";
                return this.dialogAlertDesbloquear = true;
            } 

            let data = new FormData();

            data.append('st_desbloqueado', 1);
            data.append('st_repetir_nota', 0);
            data.append('tx_justificativa', this.justificativa);                
            data.append('id_servidor', this.id_servidor);

            desbloquearAvaliacaoExterna(data).then(response => {
                this.gerarRelatorio();
                this.dialogDesbloquear = false;
                this.dialogMsg = response.data.message;
                this.dialogSuccess =true;
                this.justificativa = '';
            }).catch(erro => {
                this.dialogMsg = "Erro ao desbloquear";
                this.dialogAlertDesbloquear = true;
                console.log(erro);
            });
        },

        showBloquearDesbloquearEmLote() {
            this.justificativa = '';
            this.radioGroup = null;

            var filtros = [];
            filtros = this.getFiltros();

            if (Object.keys(filtros[0]).length < 1) {
                return this.dialogAlertBloquearDesbloquearEmLote = true;
            } else {
                this.dialogBloquearDesbloquearEmLote = true;
            }
        },

        validaLote() {
            if(this.justificativa == '') {
                this.dialogMsg ="Por favor, insira uma justificativa explicando o motivo desta ação.";
                return this.dialogAlertDesbloquear = true;
            } 

            if(this.radioGroup == null) {
                this.dialogMsg ="Selecione uma opção.";
                return this.dialogAlertDesbloquear = true;
            } 
            this.confirmarDialogBloquearDesbloquearEmLote = true;
        },

        confirmarBloquearDesbloquearEmLote() {
            var filtros = [];
            filtros = this.getFiltros();

            let data = new FormData();

            data.append('acao', this.radioGroup);
            data.append('nm_orgao_requisitante', this.orgao);
            data.append('tx_justificativa', this.justificativa);
            
            bloquearDesbloquearEmLoteAtivosEmOutroOrgao(data,filtros).then(response => {
                this.gerarRelatorio();
                this.dialogBloquearDesbloquearEmLote = false;
                this.confirmarDialogBloquearDesbloquearEmLote = false;
                this.dialogMsg = response.data.message;
                this.dialogSuccess =true;
                this.justificativa = '';
            }).catch(erro => {
                this.dialogMsg = "Erro ao realizar ação em lote";
                this.dialogAlertDesbloquear = true;
                console.log(erro);
            });        
        },

        formataData(data) {
            return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
        }, 
    }
}
</script>