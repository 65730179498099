<template>
    <div>
        <v-row>
            <v-col cols="12" md="10"> <span class="ml-2 text-h4">Planos de Trabalho</span></v-col>
            <v-col cols="12" md="2" class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                <v-btn 
                    id="btnAddUA" 
                    color="primary" 
                    v-if="( 
                        isAdmin 
                        && antesFaseAvaliacao
                        && ciclo.id_ciclo == cicloVigente
                    ) || (
                        isGestorOrgao
                        && antesFaseAvaliacao
                        && ciclo.id_ciclo == cicloVigente
                    )"
                    @click="showDialogAddUnidadeAvaliacao()"
                >
                    <v-icon>mdi-plus</v-icon> Adicionar Unidade de Avaliação
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mb-7 mx-2 pa-3" color="grey lighten-4">
            <v-card class="mb-2 mx-2 pa-3">
                <v-select
                    :items="ciclos"
                    v-model="defaultCiclo"
                    item-text="nm_ciclo"
                    item-value="id_ciclo"
                    label="Selecione um ciclo"
                    v-on:change="getPlanosDoCiclo"
                >      
                </v-select>
            </v-card>
        </v-card>
        <v-card class="mb-7 mx-2 px-3 pt-1 grey lighten-4">
            <v-card-title>
                <span class="headline">{{ ciclo.nm_ciclo }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-expansion-panels class="mb-2" focusable multiple>
                            <v-expansion-panel 
                                class="mb-3 primary" 
                                v-for="(unidade_avaliacao,i) in ciclo.unidades_avaliacao" 
                                :key="unidade_avaliacao.id_unidade_avaliacao"
                            >
                                <div class="white--text font-weight-medium mb-5 pt-2" v-if="unidade_avaliacao.id_orgao">
                                    <span class="ml-4">
                                        {{ unidade_avaliacao.nm_orgao }}
                                    </span>
                                    <v-btn class="float-right mr-5" color="white" plain icon>
                                        <v-expansion-panel-header>
                                            <template v-slot:actions>
                                                <v-icon color="white">
                                                    $expand
                                                </v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                    </v-btn>

                                    <v-tooltip top v-if="( 
                                        isAdmin 
                                        && antesFaseAvaliacao
                                        && ciclo.id_ciclo == cicloVigente
                                    ) || (
                                        isGestorOrgao
                                        && antesFaseAvaliacao
                                        && ciclo.id_ciclo == cicloVigente
                                    )">
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn 
                                                class="float-right mr-5" 
                                                color="white" 
                                                plain 
                                                icon 
                                                v-bind="attrs" 
                                                v-on="on"
                                                @click="showDialogAddUnidadeAvaliacao(unidade_avaliacao.cd_orgao)"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>ADICIONAR UNIDADE AO ÓRGÃO</span>
                                    </v-tooltip>
                                </div>
                                <div class="white--text font-weight-medium mb-5 pt-2" v-else>
                                    <span class="ml-4">
                                        {{ unidade_avaliacao.name }}
                                    </span>
                                    <v-btn class="float-right mr-5" color="white" plain icon>
                                        <v-expansion-panel-header>
                                            <template v-slot:actions>
                                                <v-icon color="white">
                                                    $expand
                                                </v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                    </v-btn>
                                    <v-tooltip top >
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn 
                                                class="float-right mr-5" 
                                                color="white" 
                                                plain 
                                                icon 
                                                v-bind="attrs" 
                                                v-on="on"
                                                v-if="( 
                                                    isAdmin 
                                                    && antesFaseAvaliacao
                                                    && ciclo.id_ciclo == cicloVigente
                                                ) || (
                                                    isGestorOrgao
                                                    && antesFaseAvaliacao
                                                    && ciclo.id_ciclo == cicloVigente
                                                )"
                                                @click="showDialogDeletarUnidadeAvaliacao(unidade_avaliacao)"
                                            >
                                                <v-icon>mdi-delete-forever</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>DELETAR UNIDADE DE AVALIAÇÃO</span>
                                    </v-tooltip>
                                    <v-tooltip top >
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn 
                                                class="float-right mr-5" 
                                                color="white" 
                                                plain 
                                                icon 
                                                v-bind="attrs" 
                                                v-on="on"
                                                v-if="( 
                                                    isAdmin 
                                                    && antesFaseAvaliacao
                                                    && ciclo.id_ciclo == cicloVigente
                                                ) || (
                                                    isGestorOrgao
                                                    && antesFaseAvaliacao
                                                    && ciclo.id_ciclo == cicloVigente
                                                )"
                                                @click="showDialogEditarUnidadeAvaliacao(unidade_avaliacao.id_unidade_avaliacao)"
                                            >
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>EDITAR UNIDADE DE AVALIAÇÃO</span>
                                    </v-tooltip>
                                    <v-tooltip top v-if="isGestor && isAdmin==false">
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn 
                                                class="float-right mr-5" 
                                                color="white" 
                                                plain 
                                                icon 
                                                v-bind="attrs" 
                                                v-on="on"
                                                v-if="ciclo.id_ciclo == cicloVigente && antesFaseAvaliacao && (unidade_avaliacao.id_gestor_setorial == idUser || unidade_avaliacao.id_gestor_substituto == idUser)"
                                                @click="showDialogAddPlano(unidade_avaliacao,[],'Plano')"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>ADICIONAR PLANO DE TRABALHO</span>
                                    </v-tooltip>
                                    <v-tooltip top v-else>
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn 
                                                class="float-right mr-5" 
                                                color="white" 
                                                plain 
                                                icon 
                                                v-bind="attrs" 
                                                v-on="on"
                                                v-if="( 
                                                    isAdmin 
                                                    && antesFaseAvaliacao
                                                    && ciclo.id_ciclo == cicloVigente
                                                ) || (
                                                    isGestorOrgao
                                                    && antesFaseAvaliacao
                                                    && ciclo.id_ciclo == cicloVigente
                                                )"
                                                @click="showDialogAddPlano(unidade_avaliacao,[],'Plano')"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>ADICIONAR PLANO DE TRABALHO</span>
                                    </v-tooltip>
                                    <v-tooltip top >
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn 
                                                class="float-right mr-5" 
                                                color="white" 
                                                plain 
                                                icon 
                                                v-bind="attrs" 
                                                v-on="on"
                                                v-if="( 
                                                    isAdmin 
                                                    && antesFaseAvaliacao
                                                    && unidade_avaliacao.id_ua_ciclo_anterior
                                                    && ciclo.id_ciclo == cicloVigente
                                                ) || (
                                                    isGestorOrgao
                                                    && antesFaseAvaliacao
                                                    && unidade_avaliacao.id_ua_ciclo_anterior
                                                    && ciclo.id_ciclo == cicloVigente
                                                )"
                                                @click="showDialogCopiarEquipeUnidade(unidade_avaliacao.id_unidade_avaliacao)"
                                            >
                                                <v-icon>mdi-account-multiple</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>COPIAR EQUIPE TRABALHO</span>
                                    </v-tooltip>
                                </div>

                                <v-expansion-panel-content class="white" v-if="unidade_avaliacao.id_orgao">
                                    <v-treeview
                                        :items="unidade_avaliacao.unidades_avaliacao"
                                        hoverable>
                                        <template slot="label" slot-scope="{ item }">
                                            <span class="text-wrap mr-2">
                                                {{ item.name }}
                                                <router-link v-if="!item.unidade" :to="{ name: 'planos-trabalho-detalhar', params: { id: item.id } }">
                                                    <v-icon dense color="primary">mdi-file-search-outline</v-icon>
                                                </router-link>
                                            </span>

                                            <v-tooltip top >
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="( 
                                                            isAdmin 
                                                            && antesFaseAvaliacao
                                                            && unidade_avaliacao.id_ua_ciclo_anterior
                                                            && ciclo.id_ciclo == cicloVigente
                                                        ) || (
                                                            isGestorOrgao
                                                            && antesFaseAvaliacao
                                                            && unidade_avaliacao.id_ua_ciclo_anterior
                                                            && ciclo.id_ciclo == cicloVigente
                                                        )"
                                                        @click="showDialogCopiarEquipeUnidade(item.id_unidade_avaliacao)"
                                                    >
                                                        <v-icon>mdi-account-multiple</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>COPIAR EQUIPE TRABALHO - {{ item.unidade }}</span>
                                            </v-tooltip>

                                            <v-tooltip top v-if="isGestor && isAdmin == false && item.unidade">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="ciclo.id_ciclo == cicloVigente && antesFaseAvaliacao && (unidade_avaliacao.id_gestor_setorial == idUser || unidade_avaliacao.id_gestor_substituto == idUser)"
                                                        @click="showDialogAddPlano(item,[],'Plano')"
                                                    >
                                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>ADICIONAR PLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else-if="item.unidade">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="( 
                                                            isAdmin 
                                                            && antesFaseAvaliacao
                                                            && ciclo.id_ciclo == cicloVigente
                                                        ) || (
                                                            isGestorOrgao
                                                            && antesFaseAvaliacao
                                                            && ciclo.id_ciclo == cicloVigente
                                                        )"
                                                        @click="showDialogAddPlano(item,[],'Plano')"
                                                    >
                                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>ADICIONAR PLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-if="!item.unidade && isGestor && isAdmin == false">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="ciclo.id_ciclo == cicloVigente && antesFaseAvaliacao && (unidade_avaliacao.id_gestor_setorial == idUser || unidade_avaliacao.id_gestor_substituto == idUser)"
                                                        @click="showDialogAddPlano(item, [], 'Subplano')"
                                                    >
                                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>ADICIONAR SUBPLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else-if="!item.unidade">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="( 
                                                            isAdmin 
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        ) || (
                                                            isGestorOrgao
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        )"
                                                        @click="showDialogAddPlano(item, item, 'Subplano')"
                                                    >
                                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>ADICIONAR SUBPLANO DE TRABALHO</span>
                                            </v-tooltip>

                                            <v-tooltip top v-if="item.unidade">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="( 
                                                            isAdmin 
                                                            && antesFaseAvaliacao
                                                            && ciclo.id_ciclo == cicloVigente
                                                        ) || (
                                                            isGestorOrgao
                                                            && antesFaseAvaliacao
                                                            && ciclo.id_ciclo == cicloVigente
                                                        )"
                                                        @click="showDialogEditarUnidadeAvaliacao(item.id_unidade_avaliacao)"
                                                    >
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>EDITAR UNIDADE DE AVALIAÇÃO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-if="isGestor && isAdmin == false && !item.unidade">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="ciclo.id_ciclo == cicloVigente && antesFaseAvaliacao && (unidade_avaliacao.id_gestor_setorial == idUser || unidade_avaliacao.id_gestor_substituto == idUser)"
                                                        @click="showDialogEditarPlano(item.id)"
                                                    >
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>EDITAR PLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else-if="!item.unidade">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="( 
                                                            isAdmin 
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        ) || (
                                                            isGestorOrgao
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        )"
                                                        @click="showDialogEditarPlano(item.id)"
                                                    >
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>EDITAR PLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            
                                            <v-tooltip top v-if="item.unidade">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="error"
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="( 
                                                            isAdmin 
                                                            && antesFaseAvaliacao
                                                            && ciclo.id_ciclo == cicloVigente
                                                        ) || (
                                                            isGestorOrgao
                                                            && antesFaseAvaliacao
                                                            && ciclo.id_ciclo == cicloVigente
                                                        )"
                                                        @click="showDialogDeletarUnidadeAvaliacao(item)"
                                                    >
                                                        <v-icon>mdi-delete-forever</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>DELETAR UNIDADE DE AVALIAÇÃO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-if="isGestor && isAdmin == false && !item.unidade">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="error" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="ciclo.id_ciclo == cicloVigente && antesFaseAvaliacao && (unidade_avaliacao.id_gestor_setorial == idUser || unidade_avaliacao.id_gestor_substituto == idUser)"
                                                        @click="showDialogDeletarPlano(item.id)"
                                                    >
                                                        <v-icon>mdi-delete-forever</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>DELETAR PLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else-if="!item.unidade">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="error" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="( 
                                                            isAdmin 
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        ) || (
                                                            isGestorOrgao
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        )"
                                                        @click="showDialogDeletarPlano(item.id)"
                                                    >
                                                        <v-icon>mdi-delete-forever</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>DELETAR PLANO DE TRABALHO</span>
                                            </v-tooltip>
                                        </template>
                                    </v-treeview>
                                </v-expansion-panel-content>
                                <v-expansion-panel-content class="white" v-else>
                                    <p 
                                        v-for="(plano,i) in unidade_avaliacao.planos_trabalho" 
                                        :key="plano.id_plano_trabalho" 
                                        class="mx-5 my-3 font-weight-normal"
                                    >
                                        {{ plano.nm_unidade }}
                                    </p>

                                    <v-treeview
                                        :items="unidade_avaliacao.planos_trabalho"
                                        hoverable>
                                        <template slot="label" slot-scope="{ item }">
                                            <span class="text-wrap mr-2">
                                                {{ item.name }}
                                                <router-link :to="{ name: 'planos-trabalho-detalhar', params: { id: item.id } }">
                                                    <v-icon dense color="primary">mdi-file-search-outline</v-icon>
                                                </router-link>
                                            </span>
                                            <v-tooltip top v-if="isGestor && isAdmin ==false">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="ciclo.id_ciclo == cicloVigente && antesFaseAvaliacao && (unidade_avaliacao.id_gestor_setorial == idUser || unidade_avaliacao.id_gestor_substituto == idUser)"
                                                        @click="showDialogAddPlano(unidade_avaliacao, item, 'Subplano')"
                                                    >
                                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>ADICIONAR SUBPLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="( 
                                                            isAdmin 
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        ) || (
                                                            isGestorOrgao
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        )"
                                                        @click="showDialogAddPlano(unidade_avaliacao, item, 'Subplano')"
                                                    >
                                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>ADICIONAR SUBPLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-if="isGestor && isAdmin ==false">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="ciclo.id_ciclo == cicloVigente && antesFaseAvaliacao && (unidade_avaliacao.id_gestor_setorial == idUser || unidade_avaliacao.id_gestor_substituto == idUser)"
                                                        @click="showDialogEditarPlano(item.id)"
                                                    >
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>EDITAR PLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="primary" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="( 
                                                            isAdmin 
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        ) || (
                                                            isGestorOrgao
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        )"
                                                        @click="showDialogEditarPlano(item.id)"
                                                    >
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>EDITAR PLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-if="isGestor && isAdmin ==false">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="error" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="ciclo.id_ciclo == cicloVigente && antesFaseAvaliacao && (unidade_avaliacao.id_gestor_setorial == idUser || unidade_avaliacao.id_gestor_substituto == idUser)"
                                                        @click="showDialogDeletarPlano(item.id)"
                                                    >
                                                        <v-icon>mdi-delete-forever</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>DELETAR PLANO DE TRABALHO</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn 
                                                        color="error" 
                                                        icon 
                                                        v-bind="attrs" 
                                                        v-on="on"
                                                        v-if="( 
                                                            isAdmin 
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        ) || (
                                                            isGestorOrgao
                                                            && antesFaseAvaliacao
                                                            && showBtnAddSubplano
                                                            && ciclo.id_ciclo == cicloVigente
                                                        )"
                                                        @click="showDialogDeletarPlano(item.id)"
                                                    >
                                                        <v-icon>mdi-delete-forever</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>DELETAR PLANO DE TRABALHO</span>
                                            </v-tooltip>
                                        </template>
                                    </v-treeview>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
        <v-dialog 
            v-model="dialogAddPlano" 
            width="100%" 
            fullscreen 
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Adicionar {{tipoPlano}} de Trabalho
                        </v-toolbar-title>             
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="dialogAddPlano = false">
                            <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card>
                <br>
                <h3 class="primary--text text-center py-6">
                    Adicionar {{tipoPlano}} de Trabalho
                </h3>
                <v-card-text>
                    <h2 class="font-weight-light text-center">
                        É obrigatório selecionar uma unidade.
                    </h2><br>
                    <v-card
                        class="mx-auto"
                        max-width="800"
                    >
                        <v-sheet class="pa-4 primary">
                        <v-text-field
                            v-model="search"
                            label="Procurar Unidade"
                            dense 
                            dark
                            append-icon="mdi-magnify"
                            hide-details="auto" 
                            outlined 
                            clearable 
                            clear-icon="mdi-close-circle-outline"
                        ></v-text-field>
                        </v-sheet>
                        <v-card-text class="border">
                            <v-treeview
                                :items="items"
                                :search="search"
                                :filter="filter"
                                :open.sync="open"
                            >
                                <template slot="label" slot-scope="{ item }">
                                    <span class="text-wrap">
                                    <v-checkbox
                                        class="ml-2"
                                        v-model="selected"
                                        :label="item.name"
                                        :value="item.name"
                                        plain
                                        @click="selecionaUnidade(item)"
                                    ></v-checkbox>
                                    </span>
                                </template>
                            </v-treeview>
                        </v-card-text>

                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-text-field 
                                        id="nomePlano" 
                                        @keyup="uppercase"
                                        v-model="nm_unidade" 
                                        ref="nomePlano" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome do Plano de Trabalho" 
                                        outlined 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-text-field 
                                        id="nomeUnidadeAvaliacao" 
                                        v-model="plano.nm_unidade_avaliacao" 
                                        ref="nomeUnidadeAvaliacao" 
                                        dense 
                                        hide-details="auto" 
                                        label="Unidade de Avaliação" 
                                        outlined 
                                        disabled
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-text-field 
                                        id="nomeUnidade" 
                                        v-model="unidade" 
                                        ref="nomeUnidade" 
                                        dense 
                                        hide-details="auto" 
                                        label="Unidade" 
                                        outlined 
                                        disabled
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                        <v-row v-if="plano_pai.name" dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-text-field 
                                        id="planoPai" 
                                        v-model="plano_pai.name" 
                                        ref="planoPai" 
                                        dense 
                                        hide-details="auto" 
                                        label="Plano de Trabalho Superior" 
                                        outlined 
                                        disabled
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                    </v-card>
                    
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogAddPlano = false">Fechar</v-btn>
                <v-btn color="primary"  text @click="salvarPlano()">Salvar</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAlert" width="580">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
         <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                Feito com sucesso.
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>

        <v-dialog 
            v-model="dialogEditarPlano" 
            width="100%" 
            fullscreen 
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Editar Plano de Trabalho
                        </v-toolbar-title>             
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="dialogEditarPlano = false">
                            <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card>
                <br>
                <h3 class="primary--text text-center py-6">
                    Editar Plano de Trabalho
                </h3>
                <v-card-text>
                    <v-card
                        class="mx-auto"
                        max-width="800"
                    >
                        <v-sheet class="pa-4 primary">
                        <v-text-field
                            v-model="search"
                            label="Procurar Unidade"
                            dense 
                            dark
                            append-icon="mdi-magnify"
                            hide-details="auto" 
                            outlined 
                            clearable 
                            clear-icon="mdi-close-circle-outline"
                        ></v-text-field>
                        </v-sheet>
                        <v-card-text class="border">
                            <v-treeview
                                :items="items"
                                :search="search"
                                :filter="filter"
                                :open.sync="open"
                            >
                                <template slot="label" slot-scope="{ item }">
                                    <span class="text-wrap">
                                    <v-checkbox
                                        class="ml-2"
                                        v-model="selected"
                                        :label="item.name"
                                        :value="item.name"
                                        plain
                                        @click="selecionaUnidade(item)"
                                    ></v-checkbox>
                                    </span>
                                </template>
                            </v-treeview>
                        </v-card-text>

                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-text-field 
                                        id="nomePlano" 
                                        @keyup="uppercase"
                                        v-model="nm_unidade" 
                                        ref="nomePlano" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome do Plano de Trabalho" 
                                        outlined 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-text-field 
                                        id="nomeUnidadeAvaliacao" 
                                        v-model="plano.nm_unidade_avaliacao" 
                                        ref="nomeUnidadeAvaliacao" 
                                        dense 
                                        hide-details="auto" 
                                        label="Unidade de Avaliação" 
                                        outlined 
                                        disabled
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-text-field 
                                        id="nomeUnidade" 
                                        v-model="unidade" 
                                        ref="nomeUnidade" 
                                        dense 
                                        hide-details="auto" 
                                        label="Unidade" 
                                        outlined 
                                        disabled
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                        <v-row v-if="plano_pai.name" dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-text-field 
                                        id="planoPai" 
                                        v-model="plano_pai.name" 
                                        ref="planoPai" 
                                        dense 
                                        hide-details="auto" 
                                        label="Plano de Trabalho Superior" 
                                        outlined 
                                        disabled
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                    </v-card>
                    
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogEditarPlano = false">Fechar</v-btn>
                <v-btn color="primary"  text @click="editarPlano(idPlano)">Editar</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogUnidadeAvaliacao" 
            width="100%" 
            fullscreen 
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Adicionar Unidade de Avaliação
                        </v-toolbar-title>             
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="dialogUnidadeAvaliacao = false">
                            <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card>
                <br>
                <h3 class="primary--text text-center py-6">
                    Adicionar Unidade de Avaliação
                </h3>
                <v-card-text>
                    <v-card
                        class="mx-auto"
                        max-width="800"
                    >
                        <br>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-select
                                    id="orgao"
                                    v-model="orgao"
                                    :items="orgaos"
                                    hide-details="auto" 
                                    dense
                                    clearable
                                    label="Órgão"
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-text-field 
                                        id="new_nm_unidade_avaliacao" 
                                        @keyup="uppercase"
                                        v-model="new_nm_unidade_avaliacao" 
                                        ref="new_nm_unidade_avaliacao" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome da Unidade de Avaliação" 
                                        outlined 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                        <br>
                        <v-card class="mx-6 pa-2">
                            <h3 class="ml-1">Gestor Setorial</h3>
                             <v-row dense class="mb-3 pa-2">
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field
                                        id="cpf_siape_gestor" 
                                        ref="cpf_siape_gestor" 
                                        v-model="nr_siape_nr_cpf_gestor"
                                        append-icon="mdi-magnify"
                                        label="Digite o CPF ou SIAPE"
                                        single-line
                                        v-mask="'###########'"
                                        hide-details="auto"
                                        v-on:change="getGestorSetorial(nr_siape_nr_cpf_gestor)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" class="mx-0 px-3">
                                    <v-text-field 
                                        id="gestor_nome" 
                                        ref="gestor_nome" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome" 
                                        v-model="nm_gestorSetorial" 
                                        outlined 
                                        disabled
                                        clearable 
                                        clear-icon="mdi-close-circle-outline" 
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        
                        <v-card class="ma-6 mb-4 pb-4 pa-4">
                            <h3>Gestor Setorial Substituto</h3>
                             <v-row dense class="mb-3 pa-2">
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field
                                        id="nr_siape_nr_cpf_gestor_substituto" 
                                        ref="nr_siape_nr_cpf_gestor_substituto" 
                                        v-model="nr_siape_nr_cpf_gestor_substituto"
                                        append-icon="mdi-magnify"
                                        label="Digite o CPF ou SIAPE"
                                        single-line
                                        v-mask="'###########'"
                                        hide-details="auto"
                                        v-on:change="getGestorSetorialSubstituto(nr_siape_nr_cpf_gestor_substituto)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" class="mx-0 px-3">
                                    <v-text-field 
                                        id="substituto_nome" 
                                        ref="substituto_nome" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome" 
                                        v-model="nm_gestorSetorialSubstituto" 
                                        outlined 
                                        disabled
                                        clearable 
                                        clear-icon="mdi-close-circle-outline" 
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <br>
                    </v-card>
                    
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogUnidadeAvaliacao = false">Fechar</v-btn>
                <v-btn color="primary"  text @click="salvarUnidadeDeAvaliacao()">Salvar</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog 
            v-model="dialogEditarUnidadeAvaliacao" 
            width="100%" 
            fullscreen 
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark>
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Editar Unidade de Avaliação
                        </v-toolbar-title>             
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="dialogEditarUnidadeAvaliacao = false">
                            <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card>
                <br>
                <h3 class="primary--text text-center py-6">
                    Editar Unidade de Avaliação
                </h3>
                <v-card-text>
                    <v-card
                        class="mx-auto"
                        max-width="800"
                    >
                        <br>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="12" class="mx-0 px-3">
                                <v-text-field 
                                        id="new_nm_unidade_avaliacao" 
                                        @keyup="uppercase"
                                        v-model="new_nm_unidade_avaliacao" 
                                        ref="new_nm_unidade_avaliacao" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome da Unidade de Avaliação" 
                                        outlined 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                        <br>
                        <v-card class="mx-6 pa-2">
                            <h3 class="ml-1">Gestor Setorial</h3>
                             <v-row dense class="mb-3 pa-2">
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field
                                        id="cpf_siape_gestor" 
                                        ref="cpf_siape_gestor" 
                                        v-model="nr_siape_nr_cpf_gestor"
                                        append-icon="mdi-magnify"
                                        label="Digite o CPF ou SIAPE"
                                        single-line
                                        v-mask="'###########'"
                                        hide-details="auto"
                                        v-on:change="getGestorSetorial(nr_siape_nr_cpf_gestor)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" class="mx-0 px-3">
                                    <v-text-field 
                                        id="gestor_nome" 
                                        ref="gestor_nome" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome" 
                                        v-model="nm_gestorSetorial" 
                                        outlined 
                                        disabled
                                        clearable 
                                        clear-icon="mdi-close-circle-outline" 
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        
                        <v-card class="ma-6 mb-4 pb-4 pa-4">
                            <h3>Gestor Setorial Substituto</h3>
                             <v-row dense class="mb-3 pa-2">
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field
                                        id="nr_siape_nr_cpf_gestor_substituto" 
                                        ref="nr_siape_nr_cpf_gestor_substituto" 
                                        v-model="nr_siape_nr_cpf_gestor_substituto"
                                        append-icon="mdi-magnify"
                                        label="Digite o CPF ou SIAPE"
                                        single-line
                                        v-mask="'###########'"
                                        hide-details="auto"
                                        v-on:change="getGestorSetorialSubstituto(nr_siape_nr_cpf_gestor_substituto)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" class="mx-0 px-3">
                                    <v-text-field 
                                        id="substituto_nome" 
                                        ref="substituto_nome" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome" 
                                        v-model="nm_gestorSetorialSubstituto" 
                                        outlined 
                                        disabled
                                        clearable 
                                        clear-icon="mdi-close-circle-outline" 
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <br>
                    </v-card>
                    
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogEditarUnidadeAvaliacao = false">Fechar</v-btn>
                <v-btn color="primary"  text @click="editarUnidade(idUnidade)">Salvar</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogDeletarPlano" 
            width="100%" 
            fullscreen 
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark>
                            <v-icon>mdi-delete-forever</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Deletar Plano de Trabalho
                        </v-toolbar-title>             
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="dialogDeletarPlano = false">
                            <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card>
                <br>
                <h3 class="primary--text text-center py-6">
                    Deletar Plano de Trabalho
                </h3>
                <v-card-text>
                    <v-card
                        class="mx-auto"
                        max-width="800"
                    >
                        <h3 class="text-center pa-4">
                            Tem certeza que deseja deletar o plano de trabalho? 
                        </h3>
                        <p class="text-center blue-grey--text mt-2">
                            <v-icon color="warning" class="mr-1">mdi-alert-circle-outline</v-icon>
                            Será excluído junto com o plano de trabalho toda a sua hierarquia de planos e subplanos se houver
                        </p>
                        <v-card v-if="orderedPlanosFilhos.length"  style="margin-top:30px;" class="mb-2 mx-5 pa-4">
                            <v-card-title class="text-h6">
                                Subplanos que também serão excluídos:
                            </v-card-title>
                            <v-treeview :items="orderedPlanosFilhos" hoverable>
                                <template slot="label" slot-scope="{ item }">
                                    <span class="text-wrap">
                                        {{ item.name }}
                                        <router-link :to="{ name: 'planos-trabalho-detalhar', params: { id: item.id } }">
                                            <v-icon dense color="primary">mdi-file-search-outline</v-icon>
                                        </router-link>
                                    </span>
                                </template>
                            </v-treeview>
                        </v-card>  
                        <br>     
                    </v-card>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogDeletarPlano = false">Fechar</v-btn>
                <v-btn color="error"  text @click="deletarPlano(idPlano)">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogDeletarUnidade" 
            width="100%" 
            fullscreen 
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark>
                            <v-icon>mdi-delete-forever</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Deletar Unidade de Avaliação
                        </v-toolbar-title>             
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="dialogDeletarUnidade = false">
                            <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card>
                <br>
                <h3 class="primary--text text-center py-6">
                    Deletar Unidade de Avaliação
                </h3>
                <v-card-text>
                    <v-card
                        class="mx-auto"
                        max-width="800"
                    >
                        <h3 class="text-center pa-4">
                            Tem certeza que deseja deletar a Unidade de Avaliação? 
                        </h3>
                        <p class="text-center blue-grey--text mt-2">
                            <v-icon color="warning" class="mr-1">mdi-alert-circle-outline</v-icon>
                            Será excluído junto com a unidade de avaliação todos os seus planos e subplanos se houver.
                        </p>
                        <v-card v-if="treePlanosUnidadeAvaliacao.length"  style="margin-top:30px;" class="mb-2 mx-5 pa-4">
                            <v-card-title class="text-h6">
                                Planos e subplanos que serão excluídos:
                            </v-card-title>
                            <v-treeview :items="treePlanosUnidadeAvaliacao" hoverable>
                                <template slot="label" slot-scope="{ item }">
                                    <span class="text-wrap">
                                        {{ item.name }}
                                        <router-link :to="{ name: 'planos-trabalho-detalhar', params: { id: item.id } }">
                                            <v-icon dense color="primary">mdi-file-search-outline</v-icon>
                                        </router-link>
                                    </span>
                                </template>
                            </v-treeview>
                        </v-card>  
                        <br>     
                    </v-card>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogDeletarUnidade = false">Fechar</v-btn>
                <v-btn color="error"  text @click="deletarUnidade(idUnidade)">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogCopiarEquipe" 
            width="50%" 
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark>
                            <v-icon>mdi-account-multiple</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Copiar equipe do ciclo anterior
                        </v-toolbar-title>             
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="dialogCopiarEquipe = false">
                            <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card>
                <br>
                <v-card-text>
                    <v-container>
                        <v-alert cols="12" type="warning" prominent>
                            <span>
                                A equipe de trabalho dessa unidade será copiada do ciclo anterior, tem certeza que deseja efetuar essa operação?
                            </span>
                        </v-alert>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="grey darken-1" text @click="dialogCopiarEquipe = false">Não</v-btn>
                        <v-btn color="primary" text @click="copiarEquipeUnidade(idUnidade)">Sim</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
    import moment from 'moment';

    import { getCiclos, getCicloPlanosTrabalho } from '../api/ciclo';
    
    import { getUnidadesTree } from '../api/unidade';

    import { salvarPlanoDeTrabalho } from '../api/plano_trabalho';
    import { getPlanoTrabalhoById } from '../api/plano_trabalho';
    import { editarPlanoDeTrabalho } from '../api/plano_trabalho';
    import { getPlanoTrabalho } from '../api/plano_trabalho';
    import { deletarPlanoDeTrabalho } from '../api/plano_trabalho';
    import { deletarUnidadeAvaliacao } from '../api/plano_trabalho';
    
    import { getGestorByCPF } from '../api/servidor';
    import { getGestorBySiape } from '../api/servidor';
    import { getServidorWithOrgao } from '../api/servidor';

    import { getCiclosVigentes } from '../api/ciclo';

    import { getFaseAvaliacaoCicloVigente } from '../api/ciclo';

    import { salvarUnidadeAvaliacao } from '../api/unidade_avaliacao';
    import { getUnidadeAvaliacaoById } from '../api/unidade_avaliacao';
    import { editarUnidadeDeAvaliacao } from '../api/unidade_avaliacao';

    import { copiarEquipeUnidade, copiarEquipePlano } from '../api/equipe_trabalho';

    import { getOrgaos } from '../api/orgao';

    export default {
        name: 'PlanosTrabalhoListar',
        data() {
            return {
                ciclos: [],
                defaultCiclo: [],
                ciclo: [],
                ciclosNaoVigentes: [],
                dialogAddPlano: false,
                plano: [],
                items: [],
                open: [1, 2],
                search: null,
                caseSensitive: false, 
                dialog: false,
                selected:null,
                nm_unidade: null,
                id_unidade:null,
                plano_pai: [],
                tipoPlano:'',
                dialogSuccess:false,
                dialogMsg: '',
                dialogAlert: false,
                id_unidade_avaliacao: null,
                id_ciclo: null,
                unidade: null,
                dialogEditarPlano: false,
                idPlano: null,
                dialogUnidadeAvaliacao: false,
                nr_siape_nr_cpf_gestor: null,
                nr_siape_nr_cpf_gestor_substituto: null,
                nm_gestorSetorial: null,
                nm_gestorSetorialSubstituto: null,
                new_nm_unidade_avaliacao: null,
                cicloVigente:null,
                dialogEditarUnidadeAvaliacao: false,
                idUnidade: null,
                showBtnAddSubplano: false,
                isAdmin: false,
                isGestor: false,
                idUser: null,
                antesFaseAvaliacao: false,
                dt_inicio_avaliacao: '',
                dialogDeletarPlano: false,
                plano_trabalho: [],
                dialogDeletarUnidade: false,
                treePlanosUnidadeAvaliacao:[],
                unidadeAvaliacaoWithPlanos: [],
                dialogCopiarEquipe: false,
                orgao:'',
                orgaos: [],
                isGestorOrgao: false,
            };
        },
       created() {
            getFaseAvaliacaoCicloVigente().then(response => {
                if (response.data.data.length > 0) {
                    var dt_agora = moment().format('YYYY/MM/DD');
                    var dt_fase_avaliacao = moment(response.data.data[0].dt_inicio).format('YYYY/MM/DD');
                    
                    if (dt_agora < dt_fase_avaliacao) {
                        this.antesFaseAvaliacao = true;
                    } else {
                        this.antesFaseAvaliacao = false;
                    }
                } 
                
            });

            getCiclosVigentes(this.$store.getters['auth/usuarioAutenticado']).then(response => {
                if (response.data.data.length > 0) {
                    this.cicloVigente = response.data.data[0].id_ciclo;
                }
                
            });

            if (
                this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("pontoFocal") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
            ) {
                this.showBtnAddSubplano = true;
            }

            if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") >= 0) {
                this.isAdmin = true;
            }

            if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorSetorial") >= 0) {
                this.isGestor = true;
            }

            if (
                this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
                && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
            ) {
                this.isGestorOrgao = true;
            }

            this.idUser = this.$store.getters['auth/usuarioAutenticado'];

            getCiclos().then(response => { 
                if (response.data.data.length > 0) {
                    this.ciclos = response.data.data; 
                    this.defaultCiclo = this.ciclos[0];
                    
                    getCicloPlanosTrabalho(this.defaultCiclo.id_ciclo).then(response => {
                        this.ciclo = response.data.data;
                    });
                }
            });
            
            getUnidadesTree()
                .then(response => { 
                    this.items = response.data.data; 
            });
        },
        computed: {
            filter () {
                return this.caseSensitive
                ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                : undefined
            },

            orderedPlanosFilhos: function () {
                return _.orderBy(this.plano_trabalho.planos_filhos, 'name');
            },
        },
        methods: {
            getPlanosDoCiclo(id_ciclo) {
                // if (this.ciclosNaoVigentes.filter(ciclo => ciclo.id_ciclo === id_ciclo).length > 0){
                //     getCicloPlanosTrabalhoNaoVigentes(id_ciclo)
                //         .then(response => {
                //             this.cicloNV = response.data.data;
                //             this.ciclo = response.data.data;
                //         });
                // } else {
                getCicloPlanosTrabalho(id_ciclo).then(response => {
                    this.ciclo = response.data.data;
                });
                // }
            },

            showDialogAddPlano(unidadeAvaliacao, pai = [], tipo ='') {
                this.dialogAddPlano = true;

                this.selected = null;
                this.id_unidade = null;
                this.nm_unidade = null;
                this.unidade = null;

                this.plano_pai = pai;
                this.tipoPlano = tipo;

                this.search = null;
                this.plano.nm_unidade_avaliacao = unidadeAvaliacao.nm_unidade_avaliacao;
                this.id_unidade_avaliacao = unidadeAvaliacao.id_unidade_avaliacao;
                this.id_ciclo = unidadeAvaliacao.id_ciclo;
                this.idPlano = null;
            },

            selecionaUnidade(item) {
                this.id_unidade = item.id;
                this.nm_unidade = item.name;
                this.unidade = item.name;
            },

            uppercase() {
                if (this.dialogUnidadeAvaliacao == true || this.dialogEditarUnidadeAvaliacao == true) {
                    this.new_nm_unidade_avaliacao = this.new_nm_unidade_avaliacao =null ? null : this.new_nm_unidade_avaliacao.toUpperCase();
                } else {
                    this.nm_unidade =this.nm_unidade =null ? null : this.nm_unidade.toUpperCase();
                }

            },

            salvarPlano() {
                if (this.id_unidade ==null) {
                    this.dialogMsg = "Selecione uma unidade";
                    return this.dialogAlert = true;
                }

                if (this.nm_unidade == null || this.nm_unidade == '') {
                    this.dialogMsg = "Nome do plano é obrigatório";
                    return this.dialogAlert = true;
                }

                let data = new FormData();
                data.append('id_ciclo', this.id_ciclo);
                data.append('id_unidade_avaliacao', this.id_unidade_avaliacao);
                data.append('id_unidade', this.id_unidade);
                data.append('nm_unidade', this.nm_unidade);

                if (this.plano_pai.id) {
                    data.append('id_plano_pai', this.plano_pai.id);
                }

                salvarPlanoDeTrabalho(data).then(response => {
                    this.dialogSuccess =true;
                    this.dialogAddPlano = false;
                    this.getPlanosDoCiclo(this.id_ciclo);
                }).catch(erro => {
                    this.dialogMsg = "Erro ao salvar.";
                    this.dialogAlert = true;
                });
            },

            showDialogEditarPlano(id_plano_trabalho) {
                this.dialogEditarPlano = true;
                this.search = null;

                getPlanoTrabalhoById(id_plano_trabalho).then(response => {
                    this.idPlano = response.data.data[0].id_plano_trabalho;
                    this.id_unidade = response.data.data[0].id_unidade;
                    this.nm_unidade = response.data.data[0].nm_unidade;
                    this.unidade =response.data.data[0].unidade.sg_unidade + ' - '+ response.data.data[0].unidade.nm_unidade;
                    this.plano.nm_unidade_avaliacao = response.data.data[0].unidade_avaliacao.nm_unidade_avaliacao;
                    this.id_ciclo = response.data.data[0].id_ciclo;
                });
            },

            editarPlano(id) {
                
                var data = [];
                var obj = {};

                if (this.id_unidade ==null) {
                    this.dialogMsg = "Selecione uma unidade";
                    return this.dialogAlert = true;
                } else {
                    obj['id_unidade'] = this.id_unidade;
                }

                if (this.nm_unidade == null || this.nm_unidade == '') {
                    this.dialogMsg = "Nome do plano é obrigatório";
                    return this.dialogAlert = true;
                } else {
                    obj['nm_unidade'] = this.nm_unidade;
                }

                data.push(obj);

                editarPlanoDeTrabalho(id, data[0]).then(response => {
                    this.dialogSuccess =true;
                    this.dialogEditarPlano = false;
                    this.getPlanosDoCiclo(this.id_ciclo);
                }).catch(erro => {
                    this.dialogMsg = "Erro ao salvar.";
                    this.dialogAlert = true;
                });
            },

            showDialogAddUnidadeAvaliacao(cdOrgao) 
            {
                this.orgao = [];
                if (this.isGestorOrgao) {
                    getServidorWithOrgao(this.$store.getters['auth/usuarioAutenticado']).then(response => {
                        if (response.data.data.orgao) {
                            var org = response.data.data.orgao;
                            this.orgaos.unshift({
                                text: org.sg_sigla + ' - ' + org.nm_orgao, 
                                value: org.cd_orgao                        
                            });
                            this.orgao = org.cd_orgao;
                        }
                    });
                } else {
                    getOrgaos().then(response => {
                        this.orgaos = response.data.data; 
                        if (this.orgaos.length > 0) {
                            this.orgaos.map(org =>{
                                org.text = org.sg_sigla + ' - ' + org.nm_orgao
                                org.value = org.cd_orgao
                            })

                            if ( cdOrgao !== undefined ) {
                                this.orgao = this.orgaos.find(org => org.cd_orgao == cdOrgao);
                            }
                        } else {
                            this.orgaos = [];
                        }
                    });
                }

                this.dialogUnidadeAvaliacao = true;
                this.nm_gestorSetorial = null;
                this.nm_gestorSetorialSubstituto = null;
                this.gestorSetorial = null;
                this.gestorSetorialSubstituto = null;
                this.new_nm_unidade_avaliacao = null;
                this.idUnidade = null;
                this.nr_siape_nr_cpf_gestor = null;
                this.nr_siape_nr_cpf_gestor_substituto = null;
            },

            getGestorSetorial(numero) {
           
                if (numero.length > 8) {
                    getGestorByCPF(numero).then(response => {
                        this.gestorSetorial = response.data.data;
                        this.nm_gestorSetorial = this.gestorSetorial.nm_servidor;

                        if (this.gestorSetorialSubstituto) {
                            if (this.gestorSetorialSubstituto.id_servidor == this.gestorSetorial.id_servidor) {
                                this.nr_siape_nr_cpf_gestor = null;
                                this.gestorSetorial = null;
                                this.nm_gestorSetorial = null;
                                this.dialogMsg = "Mesmo servidor nas duas funções";
                                this.dialogAlert = true;
                            }
                        }

                    }).catch(e => {
                        this.nr_siape_nr_cpf_gestor = null;
                        this.gestorSetorial = null;
                        this.nm_gestorSetorial = null;
                        this.dialogMsg = "Não encontrado.";
                        this.dialogAlert = true;
                    });
                } else {
                    getGestorBySiape(numero).then(response => {
                        this.gestorSetorial = response.data.data;
                        this.nm_gestorSetorial = this.gestorSetorial.nm_servidor;

                        if (this.gestorSetorialSubstituto) {
                            if (this.gestorSetorialSubstituto.id_servidor == this.gestorSetorial.id_servidor) {
                                this.nr_siape_nr_cpf_gestor = null;
                                this.gestorSetorial = null;
                                this.nm_gestorSetorial = null;
                                this.dialogMsg = "Mesmo servidor nas duas funções";
                                this.dialogAlert = true;
                            }
                        }
                    }).catch(e => {
                        this.nr_siape_nr_cpf_gestor = null;
                        this.gestorSetorial = null;
                        this.nm_gestorSetorial = null;
                        this.dialogMsg = "Não encontrado.";
                        this.dialogAlert = true;
                    });
                }                       
            },

            getGestorSetorialSubstituto(numero) {
           
                if (numero.length > 8) {
                    getGestorByCPF(numero).then(response => {
                        this.gestorSetorialSubstituto = response.data.data;
                        this.nm_gestorSetorialSubstituto = this.gestorSetorialSubstituto.nm_servidor;

                        if (this.gestorSetorial) {
                            if (this.gestorSetorial.id_servidor == this.gestorSetorialSubstituto.id_servidor) {
                                this.nr_siape_nr_cpf_gestor_substituto = null;
                                this.gestorSetorialSubstituto = null;
                                this.nm_gestorSetorialSubstituto = null;
                                this.dialogMsg = "Mesmo servidor nas duas funções";
                                this.dialogAlert = true;
                            }
                        }

                    }).catch(e => {
                        this.nr_siape_nr_cpf_gestor_substituto = null;
                        this.gestorSetorialSubstituto = null;
                        this.nm_gestorSetorialSubstituto = null;
                        this.dialogMsg = "Não encontrado.";
                        this.dialogAlert = true;
                    });
                } else {
                    getGestorBySiape(numero).then(response => {
                        this.gestorSetorialSubstituto = response.data.data;
                        this.nm_gestorSetorialSubstituto = this.gestorSetorialSubstituto.nm_servidor;

                        if (this.gestorSetorial) {
                            if (this.gestorSetorial.id_servidor == this.gestorSetorialSubstituto.id_servidor) {
                                this.nr_siape_nr_cpf_gestor_substituto = null;
                                this.gestorSetorialSubstituto = null;
                                this.nm_gestorSetorialSubstituto = null;
                                this.dialogMsg = "Mesmo servidor nas duas funções";
                                this.dialogAlert = true;
                            }
                        }
                    }).catch(e => {
                        this.nr_siape_nr_cpf_gestor_substituto = null;
                        this.gestorSetorialSubstituto = null;
                        this.nm_gestorSetorialSubstituto = null;
                        this.dialogMsg = "Não encontrado.";
                        this.dialogAlert = true;
                    });
                }                       
            },

            salvarUnidadeDeAvaliacao() {
                if (this.orgao == null || this.orgao == '') {
                    this.dialogMsg = "Órgão é obrigatório.";
                    return this.dialogAlert = true;
                }

                if (this.new_nm_unidade_avaliacao == null || this.new_nm_unidade_avaliacao == '') {
                    this.dialogMsg = "Nome da unidade é obrigatório.";
                    return this.dialogAlert = true;
                }

                let data = new FormData();
                data.append('id_ciclo', this.cicloVigente);
                data.append('nm_unidade_avaliacao', this.new_nm_unidade_avaliacao);
                if ( typeof this.orgao === "number" ) {
                    data.append('cd_orgao', this.orgao);
                } else {
                    data.append('cd_orgao', this.orgao.cd_orgao);
                }

                if (this.gestorSetorial) {
                    data.append('id_gestor_setorial', this.gestorSetorial.id_servidor);
                }

                if (this.gestorSetorialSubstituto) {
                    data.append('id_gestor_substituto', this.gestorSetorialSubstituto.id_servidor);
                }
                
                salvarUnidadeAvaliacao(data).then(response => {
                    this.dialogSuccess =true;
                    this.dialogUnidadeAvaliacao = false;
                    this.getPlanosDoCiclo(this.cicloVigente);
                }).catch(erro => {
                    this.dialogMsg = "Erro ao salvar.";
                    this.dialogAlert = true;
                });
            },

            showDialogEditarUnidadeAvaliacao(id_unidade_avaliacao) {
                this.dialogEditarUnidadeAvaliacao = true;

                getUnidadeAvaliacaoById(id_unidade_avaliacao).then(response => {
                    this.idUnidade = response.data.data[0].id_unidade_avaliacao;
                    this.gestorSetorial = response.data.data[0].gestor_setorial;
                    this.gestorSetorialSubstituto = response.data.data[0].gestor_substituto;

                    this.nm_gestorSetorial = this.gestorSetorial ? this.gestorSetorial.nm_servidor : null;
                    this.nm_gestorSetorialSubstituto = this.gestorSetorialSubstituto ? this.gestorSetorialSubstituto.nm_servidor : null;

                    this.new_nm_unidade_avaliacao = response.data.data[0].nm_unidade_avaliacao;

                    this.nr_siape_nr_cpf_gestor = this.gestorSetorial ? this.gestorSetorial.nr_cpf : null;
                    this.nr_siape_nr_cpf_gestor_substituto = this.gestorSetorialSubstituto ? this.gestorSetorialSubstituto.nr_cpf : null;
                });
            },

            editarUnidade(id) {
                
                var data = [];
                var obj = {};

                if (this.new_nm_unidade_avaliacao == null || this.new_nm_unidade_avaliacao == '') {
                    this.dialogMsg = "Nome da unidade é obrigatório.";
                    return this.dialogAlert = true;
                } else {
                    obj['nm_unidade_avaliacao'] = this.new_nm_unidade_avaliacao;
                }

                if (this.gestorSetorial ==null) {
                    obj['id_gestor_setorial'] = null;
                } else {
                    obj['id_gestor_setorial'] = this.gestorSetorial.id_servidor;
                }

                if (this.gestorSetorialSubstituto ==null) {
                    obj['id_gestor_substituto'] = null;
                } else {
                    obj['id_gestor_substituto'] = this.gestorSetorialSubstituto.id_servidor;
                }

                data.push(obj);

                editarUnidadeDeAvaliacao(id, data[0]).then(response => {
                    this.dialogSuccess =true;
                    this.dialogEditarUnidadeAvaliacao = false;
                    this.getPlanosDoCiclo(this.cicloVigente);
                }).catch(erro => {
                    this.dialogMsg = "Erro ao salvar.";
                    this.dialogAlert = true;
                });
            },

            showDialogDeletarPlano(id){
                this.dialogDeletarPlano = true;
                this.idPlano = id;

                getPlanoTrabalho(id).then(response => {
                    this.plano_trabalho = response.data.data;
                });
            },

            deletarPlano(id){
                deletarPlanoDeTrabalho(id).then(response => {
                    this.dialogSuccess =true;
                    this.dialogDeletarPlano = false;
                    this.getPlanosDoCiclo(this.cicloVigente);
                }).catch(erro => {
                    this.dialogMsg = "Erro ao deletar.";
                    this.dialogAlert = true;
                });
            },

            showDialogDeletarUnidadeAvaliacao(unidade) {
                this.dialogDeletarUnidade = true;
                this.idUnidade = unidade.id_unidade_avaliacao;
                this.treePlanosUnidadeAvaliacao = unidade.children;
                this.unidadeAvaliacaoWithPlanos = unidade;
            },

            deletarUnidade(id) {
                deletarUnidadeAvaliacao(id, this.unidadeAvaliacaoWithPlanos).then(response => {
                    this.dialogSuccess =true;
                    this.dialogDeletarUnidade = false;
                    this.getPlanosDoCiclo(this.cicloVigente);
                }).catch(erro => {
                    this.dialogMsg = "Erro ao deletar.";
                    this.dialogAlert = true;
                });
            },

            showDialogCopiarEquipeUnidade(id_unidade_avaliacao) {
                this.dialogCopiarEquipe = true;
                this.idUnidade = id_unidade_avaliacao;
            },

            copiarEquipeUnidade(id) {
                copiarEquipeUnidade(id).then(response => {
                    this.dialogSuccess = true;
                    this.dialogCopiarEquipe = false;
                    this.getPlanosDoCiclo(this.cicloVigente);
                }).catch(erro => {
                    this.$router.push(
                        {
                            name: 'planos-trabalho',
                        },
                        () => this.$store.dispatch('alertas/show', {
                            tipo: 'Erro',
                            titulo: 'Erro ao salvar equipe.',
                        })
                    );
                });
            },
        }
    }
</script>