import api from './index';


const gerarExtracao = (id_ciclo) => api.get('exportar/' + id_ciclo, {
      responseType: 'blob'});


export {
    gerarExtracao,

}